import React from "react";
import { MdContentCopy } from "react-icons/md";
import { ReferralIcon } from "src/assets/svg";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { renderToastMsg } from "src/utils/functions";

const Referral = () => {
  const { user } = useProtectedRoutesContext();
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text.trim());
      renderToastMsg("Copied to clipboard", "success");
    } catch (error) {
      // renderToastMsg(error,"success");
      // console.error("Failed to copy text: ", error);
    }
  };

  const url =
    window.location.origin + "/register?refCode=" + user?.uuid?.toUpperCase();

  return (
    <>
      <div className="py-[2rem] px-[4rem] my-14 lg:my-20">
        <div className="flex flex-col items-center justify-center ">
          <ReferralIcon />

          <div className="my-14 w-[80%] lg:w-[50%]">
            <p className=" text-center text-dark text-[1.6rem] font-medium">
              Share with your friends your referral code to sign up
            </p>
          </div>

          <div className="my-3 w-[80%] mx-auto">
            <div>
              <p className="  text-lightText text-[1.4rem] font-medium">
                Share Your Link
              </p>

              <div className="flex justify-between items-center gap-6 ">
                <p className="  text-dark text-[1.4rem] font-medium">{url}</p>
                <div
                  className="  bg-[#EBF5FF] rounded-full p-[15px] cursor-pointer"
                  onClick={() => copyToClipboard(`${url}`)}
                >
                  <MdContentCopy color="var(--primary-color)" size={20} />
                </div>
              </div>
            </div>

            <div className="my-7 h-px border border-gray-200"></div>
            <div>
              <p className="  text-lightText text-[1.4rem] font-medium">
                Share Your Code
              </p>

              <div className="flex justify-between items-center gap-6 ">
                <p className="  text-dark text-[1.4rem] font-medium">
                  {user?.uuid?.toUpperCase()}
                </p>
                <div
                  className="  bg-[#EBF5FF] rounded-full p-[15px] cursor-pointer"
                  onClick={() => copyToClipboard(user?.uuid?.toUpperCase())}
                >
                  <MdContentCopy color="var(--primary-color)" size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
