import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const VerticalTabs = ({ tabs, url }) => {
  const { tabId } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(parseInt(tabId));

  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`${url}/${index}`);
  };

  return (
    <>
      <div className="min-h-[700px] flex flex-col md:flex-row relative bg-white rounded-[10px] border border-lightGray border-opacity-60">
        <div className="w-full md:w-1/4 py-6">
          <ul
            className={`text-dark ${activeTab ? "md:flex-col" : "md:flex-row"}`}
          >
            {tabs.map((tab, index) => (
              <li
                key={index}
                id={index + 1}
                onClick={() => handleTabClick(index + 1)}
                className={`cursor-pointer p-[1.5rem] border-b border-lightGray text-lightText  border-opacity-60 ${
                  activeTab === index + 1
                    ? "text-primary font-semibold"
                    : "hover:text-primary"
                }`}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-3/4 p-4 border-l">
          {tabs[activeTab - 1].content}
        </div>
      </div>
    </>
  );
};

export default VerticalTabs;
