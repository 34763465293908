import React, { useEffect, useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useCart } from "src/context/CartContext";
import { useEditCart } from "src/context/EditCartContext";
import { setToStorage } from "src/utils/functions";
import { useParams } from "react-router-dom";

const QuantityInput = ({
  product,
  productName,
  quantity,
  editRequest = false,
}) => {
  const { requestType } = useParams();
  const [error, setError] = useState(false);
  const { cartState, dispatch } = useCart();
  const editCart = useEditCart();
  const { cartState: editCartState, dispatch: editCartDispatch } = editCart;

  const itemInCart = editRequest
    ? editCartState.items.find((item) => item.product.id === product.id)
    : cartState.items.find((item) => item.product.id === product.id);

  const [inputQuantity, setInputQuantity] = useState(
    itemInCart ? itemInCart?.quantity : 0
  );

  useEffect(() => {
    setToStorage("cart", cartState);
  }, [cartState]);

  const isDisabled = !itemInCart || itemInCart?.quantity === 0;

  // const addToCart = () => {
  //   const targetDispatch = editRequest ? editCartDispatch : dispatch;

  //   if (requestType === "delivery" && itemInCart?.quantity >= quantity) {
  //     setError(true);

  //     setTimeout(() => {
  //       setError(false);
  //     }, 5000);
  //   } else {
  //     targetDispatch({
  //       type: "ADD_TO_CART",
  //       payload: {
  //         product: {
  //           ...product,
  //           productName,
  //         },
  //       },
  //     });
  //     setInputQuantity((prevQuantity) => {
  //       return prevQuantity + 1;
  //     });
  //   }
  // };

  const bulkItemToCart = () => {
    const targetDispatch = editRequest ? editCartDispatch : dispatch;
    targetDispatch({
      type: "ADD_BUlK_ITEM_TO_CART",
      payload: {
        product: {
          ...product,
          productName,
        },
        quantity: inputQuantity,
      },
    });
  };

  const handleAddToCart = (e) => {
    if (inputQuantity > 0) {
      if (
        (requestType === "delivery" && inputQuantity > quantity) ||
        (requestType === "delivery" && itemInCart?.quantity > quantity)
      ) {
        setError(true);
        setInputQuantity(itemInCart?.quantity || 0);
        setTimeout(() => {
          setError(false);
        }, 5000);
      } else {
        bulkItemToCart();
      }
    } else if (inputQuantity === 0) {
      dispatch({
        type: "REMOVE_ITEM",
        payload: {
          product: {
            ...product,
            productName,
          },
        },
      });
      setInputQuantity(0);
    }
  };

  const clearItemCart = () => {
    if (inputQuantity === 0 && !isDisabled) {
      dispatch({
        type: "REMOVE_ITEM",
        payload: {
          product: {
            ...product,
            productName,
          },
        },
      });
      setInputQuantity(0);
    }
  };

  // const removeFromCart = () => {
  //   const targetDispatch = editRequest ? editCartDispatch : dispatch;
  //   targetDispatch({
  //     type: "REMOVE_FROM_CART",
  //     payload: {
  //       product: {
  //         ...product,
  //         productName,
  //       },
  //     },
  //   });
  //   setInputQuantity((prevQuantity) => prevQuantity - 1);
  // };

  const increaseQuantity = () => {
    if (requestType === "delivery" && itemInCart?.quantity >= quantity) {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      setInputQuantity((prevQuantity) => {
        return prevQuantity + 1;
      });
    }
  };

  const decreaseQuantity = () => {
    setInputQuantity((prevQuantity) => prevQuantity - 1);
  };

  return (
    <>
      <div className="flex items-center gap-3">
        <button
          onClick={decreaseQuantity}
          className={`${
            inputQuantity === 0 ? "bg-opacity-60" : ""
          } bg-lightText text-white px-2 py-2 rounded-[5px]`}
          disabled={inputQuantity === 0}
        >
          <AiOutlineMinus size="1.6rem" />
        </button>
        <input
          type="tel"
          min="0"
          value={inputQuantity}
          onChange={(e) => {
            const newQuantity = parseInt(e.target.value, 10) || 0;
            setInputQuantity(newQuantity);
          }}
          className="px-3 py-1 text-center border rounded w-[20%]"
        />
        <button
          onClick={increaseQuantity}
          className={` bg-lightText text-white px-2 py-2 rounded-[5px]`}
        >
          <AiOutlinePlus size="1.6rem" />
        </button>
        {inputQuantity > 0 && (
          <button
            onClick={handleAddToCart}
            className={`bg-primary text-white px-4 py-2 ml-3  rounded-[5px] text-[1.4rem]`}
          >
            Add
          </button>
        )}

        {inputQuantity === 0 && !isDisabled && (
          <button
            onClick={clearItemCart}
            className={`bg-red-400 text-white px-4 py-2 ml-3  rounded-[5px] text-[1.4rem]`}
          >
            Clear
          </button>
        )}
      </div>

      {error && (
        <p className="text-red-400 text-[1.2rem] my-4 transition-opacity duration-500 ease-in-out opacity-100">
          Only {quantity} items left
        </p>
      )}
    </>
  );
};

export default QuantityInput;
