import React from "react";
import { useMutation } from "@tanstack/react-query";
import { changePassword } from "src/api";
import { renderAlert } from "src/utils/functions";

import { Formik } from "formik";
import * as yup from "yup";
import { CustomInput } from "src/component";

const ChangePassword = ({ setIsPasswordModalOpen }) => {
  const validationSchema = yup.object().shape({
    old_password: yup
      .string()
      .required("Old Password is required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    new_password: yup
      .string()
      .required("New Password is required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
  });

  const initialValues = {
    old_password: "",
    new_password: "",
  };
  const { mutate, isLoading } = useMutation(changePassword, {
    onSuccess: (res) => {
      if (res?.status === "success") {
        renderAlert({
          msg: res?.message,
          title: "Success",
          type: res.status,
        }).then(() => {
          setIsPasswordModalOpen(false);
        });
      } else {
        renderAlert({
          msg: res?.message,
          title: "Error",
          type: "error",
        });
      }
    },
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => mutate(values)}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CustomInput
              title="Old Password"
              name="old_password"
              passwordToggle
              onChange={handleChange}
              value={values.old_password}
              onBlur={handleBlur}
            />
            <CustomInput
              title=" New Password"
              name="new_password"
              passwordToggle
              onChange={handleChange}
              value={values.new_password}
              onBlur={handleBlur}
            />

            <button
              type="submit"
              disabled={isLoading}
              className="btn-loader w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
            >
              Reset Password
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};
export default ChangePassword;
