import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createConsignmentRequest,
  createDeliveryRequest,
  createPickupDeliveryRequest,
  getFulfilmentCenter,
  getRequestFees,
} from "src/api";
import { useCart } from "src/context/CartContext";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import ErrorPage from "src/pages/ErrorPage";
import {
  getFromStorage,
  removeFromStorage,
  renderAlert,
  renderNaira,
} from "src/utils/functions";

const RequestComfirmation = ({ type, setStep }) => {
  const { user } = useProtectedRoutesContext();
  const formInfo = getFromStorage("requestDetails");
  const { dispatch } = useCart();

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  const { data: fulfilmentCenter } = useQuery({
    queryKey: ["FulfilmentCenter"],
    queryFn: getFulfilmentCenter,
    suspense: true,
  });

  return (
    <>
      {type === "consignment" ? (
        <Consignment
          setStep={setStep}
          user={user}
          formInfo={formInfo}
          clearCart={clearCart}
          fulfilmentCenter={fulfilmentCenter?.data}
        />
      ) : type === "delivery" ? (
        <Delivery
          setStep={setStep}
          formInfo={formInfo}
          clearCart={clearCart}
          fulfilmentCenter={fulfilmentCenter?.data}
        />
      ) : type === "pickupAndDelivery" ? (
        <PickupAndDelivery
          setStep={setStep}
          user={user}
          formInfo={formInfo}
          clearCart={clearCart}
        />
      ) : (
        <ErrorPage />
      )}
    </>
  );
};

export const PickupAndDelivery = ({ setStep, user, formInfo, clearCart }) => {
  const navigate = useNavigate();
  const [run, setRun] = useState(true);

  useEffect(() => {
    if (run) {
      const data = {
        weight: formInfo?.order_weight,
        delivery_location_id: formInfo?.delivery_location_id,
        pickup_location_id: formInfo?.pickup_location_id,
        delivery_mode: formInfo?.delivery_mode,
      };
      getFeeMutate(data);
    }
  }, [run]);

  const {
    mutate: getFeeMutate,
    data: feesData,
    isLoading: feeIsLoading,
  } = useMutation(getRequestFees, {
    onSuccess: () => {
      setRun(false);
    },
  });

  const deliveryRes = feesData?.deliveryFee?.data;
  const pickupRes = feesData?.pickupFee?.data;
  const expectedDate = feesData?.deliveryDate?.data;

  const { mutate, isLoading } = useMutation(createPickupDeliveryRequest, {
    onSuccess: (res) => {
      if (res?.status === "success") {
        renderAlert({
          title: "Success",
          msg: res?.message,
          type: res?.status,
        }).then((result) => {
          removeFromStorage("requestDetails");
          clearCart();
          result.isConfirmed ? navigate("/requests") : navigate("/requests");
        });
      } else {
        renderAlert({
          title: "Error",
          msg: res?.message,
          type: "error",
        });
      }
    },
  });

  const calculateAmount = () => {
    if (
      formInfo?.payment_method === "POD - delivery fees only" ||
      formInfo?.payment_method === "Prepaid"
    ) {
      var result = Number(deliveryRes?.delivery_fee);

      return result;
    } else {
      var result =
        Number(deliveryRes?.delivery_fee) +
        Number(pickupRes?.pickup_fee) +
        Number(formInfo?.total_product_value);

      return result;
    }
  };

  const displayDeliveryFee = () => {
    if (
      formInfo?.payment_method === "POD - delivery fees only" ||
      formInfo?.payment_method === "Prepaid"
    ) {
      return renderNaira(deliveryRes?.delivery_fee);
    } else {
      return renderNaira(0);
    }
  };

  return (
    <>
      <div className="relative bg-white rounded-lg shadow border ">
        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] mt-[1rem] mb-[2rem] font-semibold">
              Pickup Details
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Merchant’s Name: </p>
              <p className="capitalize">{user?.name}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Phone Number: </p>
              <p>{user?.phone}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Email: </p>
              <p>{user?.email}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Pickup Address:</p>
              <p>{formInfo?.pickup_address}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>State:</p>
              <p>{formInfo?.pickup_state}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>City:</p>
              <p>{formInfo?.pickup_city}</p>
            </div>
          </div>
        </div>

        {formInfo?.pickup_instruction && (
          <div className="border-b border-gray-200 ">
            <div className="py-[2rem] px-[4rem]">
              <p className="font-medium text-dark text-[1.6rem]">
                Pickup Instruction
              </p>
              <p className="font-medium text-dark text-[1.4rem] my-5">
                {formInfo?.pickup_instruction}
              </p>
            </div>
          </div>
        )}

        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] mt-[1rem] mb-[2rem] font-semibold">
              Drop Off Details
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Recipient's Name: </p>
              <p className="capitalize">{formInfo?.recipient_name}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Recipient's Number: </p>
              <p>{formInfo?.recipient_phone}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Recipient's Email: </p>
              <p>{formInfo?.recipient_email}</p>
            </div>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Delivery Address:</p>
              <p>{formInfo?.delivery_address}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Delivery State:</p>
              <p>{formInfo?.delivery_state}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Delivery City:</p>
              <p>{formInfo?.delivery_city}</p>
            </div>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-8">
              <p className="text-primary  text-[1.4rem] font-semibold">
                Payment Method:
              </p>
              <p>{formInfo?.payment_method}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-8">
              <p className="text-primary  text-[1.4rem] font-semibold">
                Expected Delivery Date:
              </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : (
                <p>{expectedDate}</p>
              )}
            </div>
          </div>
        </div>

        {formInfo?.delivery_instruction && (
          <div className="border-b border-gray-200 ">
            <div className="py-[2rem] px-[4rem]">
              <p className="font-medium text-dark text-[1.6rem]">
                Delivery Instruction
              </p>

              <p className="font-medium text-dark text-[1.4rem] my-5">
                {formInfo?.delivery_instruction}
              </p>
            </div>
          </div>
        )}

        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Order Summary
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Total Item ({formInfo?.products?.length}): </p>
              <p>{renderNaira(formInfo?.total_product_value)}</p>
            </div>

            <p className="text-primary  text-[1.5rem] font-semibold">
              Fulfilment Fee
            </p>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Pickup Fee </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : feesData ? (
                renderNaira(Number(pickupRes?.pickup_fee))
              ) : (
                renderNaira(0)
              )}
            </div>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Delivery Fee </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : feesData ? (
                renderNaira(Number(deliveryRes?.delivery_fee))
              ) : (
                renderNaira(0)
              )}
            </div>

            <div className="flex justify-between flex-wrap font-semibold text-primary text-[1.6rem] my-5">
              <p>Total: </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : (
                <p>
                  {feesData ? renderNaira(calculateAmount()) : renderNaira(0)}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="py-[2rem] px-[4rem] flex md:flex-row gap-10">
          <button
            type="button"
            className="text-primary border border-primary  w-full  py-3  rounded-[5px]"
            onClick={() => setStep(2)}
            disabled={isLoading || feeIsLoading}
          >
            Edit Request
          </button>
          <button
            type="button"
            className="btn-loader text-white bg-primary w-full cursor-pointer  py-3 rounded-[5px]"
            disabled={isLoading || feeIsLoading}
            onClick={() => {
              const { delivery_state_id, ...formInfoData } = formInfo;
              mutate(formInfoData);
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export const Delivery = ({
  setStep,
  formInfo,
  clearCart,
  fulfilmentCenter,
}) => {
  const [run, setRun] = useState(
    formInfo?.is_customer_pickup === "no" ? true : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    const data = {
      type: "delivery",
      weight: formInfo?.order_weight,
      delivery_location_id: formInfo?.delivery_location_id,
      delivery_mode: formInfo?.delivery_mode,
      item_total: formInfo?.total_product_value,
    };
    getFeeMutate(data);
  }, []);

  const {
    mutate: getFeeMutate,
    data: feesData,
    isLoading: feeIsLoading,
  } = useMutation(getRequestFees, {
    onSuccess: () => {
      setRun(false);
    },
  });

  const deliveryRes = feesData?.deliveryFee?.data;

  const expectedDate = feesData?.deliveryDate?.data;
  const { mutate, isLoading } = useMutation(createDeliveryRequest, {
    onSuccess: (res) => {
      if (res?.status === "success") {
        renderAlert({
          title: "Success",
          msg: res?.message,
          type: res?.status,
        }).then((result) => {
          removeFromStorage("requestDetails");
          clearCart();
          result.isConfirmed ? navigate("/requests") : navigate("/requests");
        });
      } else {
        renderAlert({
          title: "Error",
          msg: res?.message,
          type: "error",
        });
      }
    },
  });

  const FCData = fulfilmentCenter?.find(
    (e) => e?.id === parseInt(formInfo?.pickup_location_id)
  );

  const calculateAmount = () => {
    if (formInfo?.is_customer_pickup === "yes") {
      var result = Number(deliveryRes?.processing_fee) || 0;

      return result;
    } else {
      if (
        formInfo?.payment_method === "Prepaid" ||
        formInfo?.payment_method === "POD - delivery fees only"
      ) {
        var result =
          Number(deliveryRes?.delivery_fee) +
            Number(deliveryRes?.processing_fee) || 0;

        return result;
      } else {
        var result =
          Number(deliveryRes?.delivery_fee) +
            Number(deliveryRes?.processing_fee) +
            Number(formInfo?.total_product_value) || 0;

        return result;
      }
    }
  };
  const displayDeliveryFee = () => {
    if (formInfo?.is_customer_pickup === "yes") {
      return renderNaira(0);
    } else {
      return renderNaira(deliveryRes?.delivery_fee);
    }
  };

  return (
    <>
      <div className="relative bg-white rounded-lg shadow border ">
        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Delivery Details
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Recipient's Name: </p>
              <p className="capitalize">{formInfo?.recipient_name}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Recipient's Number: </p>
              <p>{formInfo?.recipient_phone}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Recipient's Email: </p>
              <p>{formInfo?.recipient_email}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] mt-8">
              <p className="text-primary  text-[1.4rem] font-semibold">
                Self Pickup
              </p>
              <p className="capitalize">{formInfo?.is_customer_pickup}</p>
            </div>

            {formInfo?.is_customer_pickup === "no" && (
              <>
                <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
                  <p>Delivery Address:</p>
                  <p>{formInfo?.delivery_address}</p>
                </div>
                <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
                  <p>Delivery State:</p>
                  <p>{formInfo?.delivery_state}</p>
                </div>
                <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
                  <p>Delivery City:</p>
                  <p>{formInfo?.delivery_city}</p>
                </div>
              </>
            )}

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-8">
              <p className="text-primary  text-[1.4rem] font-semibold">
                Payment Method:
              </p>
              <p>{formInfo?.payment_method}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-8">
              <p className="text-primary  text-[1.4rem] font-semibold">
                Expected Delivery Date:
              </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : (
                <p>{expectedDate}</p>
              )}
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Fulfilment Center Details
            </p>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Name:</p>
              <p>{FCData?.name}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Email:</p>
              <p>{FCData?.email}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Phone:</p>
              <p>{FCData?.phone}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Location:</p>
              <p>{FCData?.address}</p>
            </div>
          </div>
        </div>

        {formInfo?.delivery_instruction && (
          <div className="border-b border-gray-200 ">
            <div className="py-[2rem] px-[4rem]">
              <p className="font-medium text-dark text-[1.6rem]">
                Delivery Instruction
              </p>
              <p className="font-medium text-dark text-[1.4rem] my-5">
                {formInfo?.delivery_instruction}
              </p>
            </div>
          </div>
        )}

        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Order Summary
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Total Item ({formInfo?.products?.length}): </p>
              <p>{renderNaira(formInfo?.total_product_value)}</p>
            </div>

            <p className="text-primary  text-[1.5rem] font-semibold">
              Fulfilment Fee
            </p>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Delivery Fee </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : deliveryRes?.delivery_fee ? (
                displayDeliveryFee()
              ) : (
                renderNaira(0)
              )}
            </div>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Processing Fee </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : deliveryRes?.processing_fee ? (
                renderNaira(deliveryRes?.processing_fee)
              ) : (
                renderNaira(0)
              )}
            </div>

            <div className="flex justify-between flex-wrap font-semibold text-primary text-[1.6rem] my-5">
              <p>Total: </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : (
                <p>
                  {feesData ? renderNaira(calculateAmount()) : renderNaira(0)}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="py-[2rem] px-[4rem] flex md:flex-row gap-10">
          <button
            type="button"
            className="text-primary border border-primary  w-full  py-3  rounded-[5px]"
            onClick={() => setStep(2)}
            disabled={isLoading || feeIsLoading}
          >
            Edit Request
          </button>
          <button
            type="button"
            className="btn-loader text-white bg-primary w-full  py-3 rounded-[5px]"
            disabled={isLoading || feeIsLoading}
            onClick={() => {
              const { state_id, ...formInfoData } = formInfo;

              mutate(formInfoData);
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export const Consignment = ({
  setStep,
  user,
  formInfo,
  clearCart,
  fulfilmentCenter,
}) => {
  const [run, setRun] = useState(
    formInfo?.is_self_dropoff === "no" ? true : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (run) {
      const data = {
        type: "pickup",
        weight: formInfo?.order_weight,
        pickup_location_id: formInfo?.pickup_location_id,
      };
      getFeeMutate(data);
    }
  }, [run]);

  const {
    mutate: getFeeMutate,
    data: feesData,
    isLoading: feeIsLoading,
  } = useMutation(getRequestFees, {
    onSuccess: () => {
      setRun(false);
    },
  });

  const { mutate, isLoading } = useMutation(createConsignmentRequest, {
    onSuccess: (res) => {
      if (res?.status === "success") {
        renderAlert({
          title: "Success",
          msg: res?.message,
          type: res?.status,
        }).then((result) => {
          removeFromStorage("requestDetails");
          clearCart();
          result.isConfirmed ? navigate("/requests") : navigate("/requests");
        });
      } else {
        renderAlert({
          title: "Error",
          msg: res?.message,
          type: "error",
        });
      }
    },
  });

  const FCData = fulfilmentCenter?.find(
    (e) => e?.id === parseInt(formInfo?.delivery_location_id)
  );

  return (
    <>
      <div className="relative bg-white rounded-lg shadow border ">
        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Pickup Details
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Merchant’s Name: </p>
              <p className="capitalize">{user?.name}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Phone Number: </p>
              <p>{user?.phone}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Email: </p>
              <p>{user?.email}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] mt-8">
              <p className="text-primary  text-[1.4rem] font-semibold">
                Self Drop Off
              </p>
              <p className="capitalize">{formInfo?.is_self_dropoff}</p>
            </div>
            {formInfo?.is_self_dropoff === "no" && (
              <>
                <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
                  <p>Pickup Address:</p>
                  <p>{formInfo?.pickup_address}</p>
                </div>
                <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
                  <p>State:</p>
                  <p>{formInfo?.pickup_state}</p>
                </div>
                <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
                  <p>City:</p>
                  <p>{formInfo?.pickup_city}</p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Fulfilment Center Details
            </p>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Name:</p>
              <p>{FCData?.name}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Email:</p>
              <p>{FCData?.email}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Phone:</p>
              <p>{FCData?.phone}</p>
            </div>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Location:</p>
              <p>{FCData?.address}</p>
            </div>
          </div>
        </div>

        {formInfo?.pickup_instruction && (
          <div className="border-b border-gray-200 ">
            <div className="py-[2rem] px-[4rem]">
              <p className="font-medium text-dark text-[1.6rem]">
                Pickup Instruction
              </p>
              <p className="font-medium text-dark text-[1.4rem] my-5">
                {formInfo?.pickup_instruction}
              </p>
            </div>
          </div>
        )}

        <div className="border-b border-gray-200 ">
          <div className="py-[2rem] px-[4rem]">
            <p className="text-primary  text-[1.6rem] font-semibold">
              Order Summary
            </p>

            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Total Item ({formInfo?.products?.length}): </p>
              <p>{renderNaira(formInfo?.total_product_value)}</p>
            </div>

            <p className="text-primary  text-[1.5rem] font-semibold">
              Fulfilment Fee
            </p>
            <div className="flex justify-between flex-wrap font-medium text-dark text-[1.4rem] my-5">
              <p>Pickup Fee </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : feesData?.data?.pickup_fee ? (
                renderNaira(feesData?.data?.pickup_fee)
              ) : (
                renderNaira(0)
              )}
            </div>

            <div className="flex justify-between flex-wrap font-semibold text-primary text-[1.6rem] my-5">
              <p>Total: </p>
              {feeIsLoading ? (
                <p className="text-lightGray text-[1.2rem] ">
                  fetching data ....
                </p>
              ) : (
                <p>
                  {feesData
                    ? renderNaira(Number(feesData?.data?.pickup_fee))
                    : renderNaira(0)}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="py-[2rem] px-[4rem] flex md:flex-row gap-10">
          <button
            type="button"
            className="text-primary border border-primary  w-full  py-3  rounded-[5px]"
            onClick={() => setStep(2)}
            disabled={isLoading || feeIsLoading}
          >
            Edit Request
          </button>
          <button
            type="button"
            className="btn-loader text-white bg-primary w-full  py-3 rounded-[5px]"
            disabled={isLoading || feeIsLoading}
            onClick={() => {
              const { state_id, ...formInfoData } = formInfo;

              mutate(formInfoData);
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default RequestComfirmation;
