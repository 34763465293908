import React from "react";
import { ErrorMessage } from "formik";
import styles from "./custominput.module.css";

const CustomSelect = ({ title, name, options, validate = true, ...rest }) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.title} htmlFor={name}>
        {title}
      </label>
      <select className={styles.select} name={name} defaultValue={""} {...rest}>
        <option value="" label="Select" />
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {validate && (
        <ErrorMessage
          name={name}
          component="div"
          className="text-red-500 text-[1.2rem] my-3"
        />
      )}
    </div>
  );
};

export default CustomSelect;
