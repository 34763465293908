import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import styles from "../auth.module.css";

function AuthLayout() {
  const { user, token } = useProtectedRoutesContext();

  if (!!user && !!token) return <Navigate to="/dashboard" />;

  return <Outlet />;
}

export const BannerLayout = () => {
  return (
    <>
      <div
        className={`${styles.bgImage} col-span-1 lg:col-span-1 hidden lg:block px-[5rem]`}
      >
        <div className="my-[5rem] px-[3rem]">
          <div className=" text-white text-[3.5rem] font-bold font-['Roboto']">
            SendForce Logistics Ltd
          </div>
          <div className=" text-white text-[2.4rem] font-normal font-['Roboto']">
            Enjoy fast delivery at a go
            <br /> with this all-in-one app.
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
