import React, { useState } from "react";
import { CustomInput, CustomTextArea, FileDropZone } from "src/component";
import { Formik } from "formik";
import * as yup from "yup";
import { renderAlert, renderToastMsg } from "src/utils/functions";
import { useMutation } from "@tanstack/react-query";
import { CreateProductVariant } from "src/api";

const AddVariant = ({ setIsVariantModalOpen, productId, refetch }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState("");

  const handleAddProductVariant = (values) => {
    if (uploadedFile) {
      const { name, price, unit_size, description, upc_code } = values;
      const data = {
        product_name: name,
        price,
        unit_size,
        description,
        upc_code,
        product_id: productId,
        avatar: uploadedFile,
      };
      mutate(data);
    } else {
      setError("Please upload an image before submitting.");
    }
  };

  const initialValues = {
    name: "",
    price: "",
    unit_size: "",
    description: "",
    upc_code: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Variant Name is required"),
    price: yup
      .string()
      .required("Price is required")
      .matches(
        /^[+]?(?:\d+)?(?:\.\d+)?$/,
        "Must be a positive number or a positive number with a decimal point"
      )
      .test("is-number", "Must be a number", (value) => {
        if (!value) return true;
        return !isNaN(parseFloat(value));
      }),
    unit_size: yup
      .string()
      .required("Unit Size is required")
      .matches(
        /^[+]?(?:\d+)?(?:\.\d+)?$/,
        "Must be a positive number or a positive number with a decimal point"
      )
      .test("is-number", "Must be a number", (value) => {
        if (!value) return true;
        return !isNaN(parseFloat(value));
      }),
    description: yup.string().required("Description is required"),
  });

  const handleFileUpload = (file) => {
    setUploadedFile(file);
    setError(null);
  };

  const { mutate, isLoading } = useMutation(CreateProductVariant, {
    onSuccess: (data) => {
      renderAlert({
        title: "Success",
        msg: data?.message,
        type: data?.status,
      });
      refetch();
      setIsVariantModalOpen(false);
    },
    onError: (error) => {
      renderToastMsg(error?.message);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleAddProductVariant(values)}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex flex-col px-5 ">
          <div className="my-5">
            <FileDropZone
              maxSize={1048576}
              acceptedTypes={["image/jpeg", "image/jpg", "image/png"]}
              onFileUpload={handleFileUpload}
            />
            {error && <p className="text-red-500 text-[1.3rem]">{error}</p>}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[20px] lg:gap-x-[30px] ">
            <CustomInput
              title="Product Name"
              name="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
            />
            <CustomInput
              title="Unit Weight (Kg)"
              placeholder="Unit weight"
              name="unit_size"
              onChange={handleChange}
              value={values.unit_size}
              onBlur={handleBlur}
            />
            <CustomInput
              title="Product Price"
              name="price"
              onChange={handleChange}
              value={values.price}
              onBlur={handleBlur}
            />
            <CustomInput
              title="UPC Code"
              name="upc_code"
              onChange={handleChange}
              value={values.upc_code}
              validate={false}
            />
          </div>
          <CustomTextArea
            title="Product Description"
            name="description"
            onChange={handleChange}
            value={values.description}
            onBlur={handleBlur}
          />
          <div className="my-5"></div>
          <div className="flex justify-center my-4 ">
            <button
              type="submit"
              disabled={isLoading}
              className="btn-loader p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px] w-[50%]"
            >
              Add Product Variant
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddVariant;
