import React from "react";
import { QuantityInput } from "..";
import { renderNaira } from "src/utils/functions";
import emptyCart from "src/assets/images/empty-cart.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEditCart } from "src/context/EditCartContext";
import noImage from "src/assets/images/no-image.png";

const EditRequestCard = ({ title, isSummary = false }) => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const { cartState, dispatch } = useEditCart();

  const removeItemFromCart = (product) => {
    dispatch({ type: "REMOVE_ITEM", payload: { product } });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
    navigate(`/requests/${requestId}`);
  };

  return (
    <>
      <div className="relative bg-white rounded-lg shadow border">
        <div className="border-b-2 border-b-[#a7a7a74f] p-[2.5rem] flex items-center justify-between">
          <p className="text-primary text-[1.6rem] font-semibold leading-none ">
            {title}
          </p>
          {!isSummary && (
            <div
              className="my-3 flex items-center text-danger text-[1.3rem] cursor-pointer "
              onClick={() => clearCart()}
            >
              Clear
            </div>
          )}
        </div>

        <div className="max-h-[80vh]  overflow-y-auto">
          {cartState?.items.length > 0 ? (
            cartState?.items?.map((cartItem, index) => (
              <div className="border-b border-gray-200" key={index}>
                <div className="py-[2rem] px-[2rem]">
                  <div className="flex items-center flex-wrap gap-10">
                    <div className="w-[100px]">
                      <img
                        src={cartItem?.product?.avatar ?? noImage}
                        alt={cartItem?.product?.name}
                        className={`${
                          cartItem?.product?.avatar ? "w-[100px]" : "w-[60px]"
                        } object-contain`}
                      />
                    </div>
                    <div className="lg:w-[70%]">
                      <p className="text-dark font-semibold">
                        {cartItem?.product?.productName ??
                          cartItem?.product?.product_name}
                      </p>
                      <div className="flex gap-10 text-[1.4rem] text-lightGray my-2 font-normal">
                        <p>
                          Variant:{" "}
                          {cartItem.product?.name ??
                            cartItem.product?.variant_name}
                        </p>
                      </div>

                      {isSummary ? (
                        <div className="flex justify-between items-center text-[1.5rem] font-medium text-dark gap-5">
                          <p className="font-medium">
                            {renderNaira(cartItem?.product?.price)}
                          </p>
                          <p>Quantity: {cartItem?.quantity}</p>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-between items-center text-[1.5rem] font-medium text-dark gap-5">
                            <p className="font-medium">
                              {renderNaira(cartItem?.product?.price)}
                            </p>
                            <QuantityInput
                              product={cartItem?.product}
                              productName={cartItem?.product?.productName}
                              editRequest={true}
                            />
                          </div>
                          <div
                            className="my-3 flex items-center text-danger text-[1.3rem] cursor-pointer "
                            onClick={() => removeItemFromCart(cartItem.product)}
                          >
                            Remove item
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-10 flex items-center flex-col justify-center text-[2.4rem]">
              <img src={emptyCart} alt="empty cart" className="w-[500px]" />
              <Link
                to="/requests"
                className="text-dark hover:text-primary text-[1.6rem]"
              >
                Create a Request
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditRequestCard;
