import React from "react";
import DataTable from "react-data-table-component-footer";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader";

const CustomDataTable = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="table-responsive mb-[70px] w-96 relative bg-white rounded-[10px] shadow border border-zinc-300 border-opacity-50">
      <div>{props.children}</div>
      <DataTable
        pagination
        progressComponent={<Loader spin />}
        paginationServer={false}
        paginationDefaultPage={
          searchParams.has("page") ? parseInt(searchParams.get("page")) : 1
        }
        paginationPerPage={
          searchParams.has("per_page")
            ? parseInt(searchParams.get("per_page"))
            : 10
        }
        onChangePage={(page) => {
          searchParams.set("page", page);
          setSearchParams(searchParams);
        }}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          searchParams.set("per_page", currentRowsPerPage);
          setSearchParams(searchParams);
        }}
        customStyles={{
          table: {
            style: {
              borderRadius: 10,
            },
          },
          pagination: {
            style: {
              border: "1px solid #EAEBF0",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },
          rows: {
            style: {
              fontSize: "1.4rem",
              fontWeight: 800,
              color: "#3A3A3A",
              padding: 10,
              "&:last-of-type": props.footer
                ? {
                    backgroundColor: "#0000A7",
                    color: "#fff",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }
                : {},
            },
          },
          headRow: {
            style: {
              fontSize: "1.4em !important",
              fontWeight: 500,
              color: "#2C2D33",
              background: "#F2F8FF",
              padding: 10,
            },
          },
          header: {
            style: {
              fontSize: "1.6rem",
              fontWeight: 500,
              color: "#45464E",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              display: "none",
            },
          },
          subHeader: {
            style: {
              paddingInline: "2rem",
              paddingBlock: "1.5rem",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            },
          },
        }}
        {...props}
      />
    </div>
  );
};

export default CustomDataTable;
