import React from "react";
import { ErrorMessage } from "formik";
import styles from "./custominput.module.css";

const CustomTextArea = ({ title, name, validate = true, ...rest }) => {
  return (
    <>
      <div className={styles.textAreaWrapper}>
        <label className={styles.title} htmlFor={name}>
          {title}
        </label>
        <textarea className={styles.textArea} name={name} {...rest} />
        {validate && (
          <ErrorMessage
            name={name}
            component="div"
            className="text-red-500 text-[1.2rem] my-3"
          />
        )}
      </div>
    </>
  );
};

export default CustomTextArea;
