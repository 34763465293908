import React, { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { CustomInput, CustomSelect } from "src/component";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserProfile, updateProfile, updateProfileImage } from "src/api";
import { MAX_FILE_SIZE, countries, industries } from "src/utils/constants";
import { isValidFileType, renderToastMsg } from "src/utils/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { PiCamera } from "react-icons/pi";

const Profile = () => {
  const { setUser } = useProtectedRoutesContext();

  const { data, refetch } = useQuery({
    queryKey: ["profile"],
    queryFn: getUserProfile,
    suspense: true,
  });

  const user = data?.data;
  const [selectedImage, setSelectedImage] = useState(user?.image || null);

  const initialValues = {
    firstname: user?.firstname ?? "",
    lastname: user?.lastname ?? "",
    name: user?.name ?? "",
    email: user?.email ?? "",
    business_name: user?.business_name ?? "",
    industry: user?.industry ?? "",
    phone: user?.phone ?? "",
    country: user?.country ?? "",
    state: user?.state ?? "",
    image: user?.image ?? null,
    id: user?.id ?? "",
  };

  const imageValidationSchema = yup.object().shape({
    image: yup
      .mixed()
      .required(" Profile image is required")
      .test("is-valid-type", "Not a valid image type", (value) => {
        if (typeof value === "string" || value instanceof String) {
          return true;
        } else {
          return isValidFileType(
            value && value.name.toLowerCase(),
            "courseImg"
          );
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (typeof value === "string" || value instanceof String) {
          return true;
        } else {
          return value && value.size <= MAX_FILE_SIZE;
        }
      }),
  });

  const validationSchema = yup.object().shape({
    // name: yup.string().required("Name is required"),
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    industry: yup.string().required("industry is required"),
    country: yup.string().required("Please Select a Country"),
    state: yup.string().required("Please provide your State"),
  });

  const options = industries?.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const cOptions = countries?.map((option) => ({
    value: option.name,
    label: option.name,
  }));

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: (data) => {
      renderToastMsg(data?.message, "success");
      refetch().then(({ data }) => {
        setUser(data?.data);
      });
    },
  });

  const { mutate: mutateUploadImage, isLoading: uploadIsLoading } = useMutation(
    updateProfileImage,
    {
      onSuccess: (data) => {
        renderToastMsg(data?.message, "success");
        refetch().then(({ data }) => {
          setUser(data?.data);
        });
      },
    }
  );

  return (
    <>
      <div className=" border-b border-gray-200">
        <p className="py-[2rem] px-[5rem] text-lightText text-[2rem] font-semibold">
          Profile Information
        </p>
      </div>
      <div className="py-[2rem] px-[4rem]">
        <div className="mt-14">
          <Formik
            initialValues={initialValues}
            validationSchema={imageValidationSchema}
            onSubmit={(values) => {
              const data = { id: values.id, image: values.image };
              mutateUploadImage(data);
            }}
          >
            {({ setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="flex flex-col">
                <div>
                  <div className="relative rounded-full overflow-hidden">
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      id="fileInput"
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setFieldValue("image", file);

                        const reader = new FileReader();
                        reader.onload = (e) => {
                          setSelectedImage(e.target.result);
                        };
                        if (file) {
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                    <label
                      disabled={uploadIsLoading}
                      htmlFor="fileInput"
                      className="profile-avatar cursor-pointer w-[130px] h-[130px] rounded-full bg-gray-200 flex items-center justify-center hover:bg-opacity-80 hover:bg-gray-500"
                      style={{
                        background: `linear-gradient(0deg, rgba(0, 89, 173, 0.2), rgba(0, 89, 173, 0.2)), url(${selectedImage})`,
                      }}
                    >
                      <div className="profile-avatar--select">
                        <PiCamera size={26} className="text-white" />
                      </div>
                    </label>
                  </div>

                  <ErrorMessage
                    name="image"
                    component={"div"}
                    className="text-red-500 text-[1.2rem] my-3"
                  />
                  <div className="text-lightText text-[1.3rem] my-5">
                    Noted: Accepted format are jpg, jpeg, png and Max allowed
                    size is 500KB
                  </div>
                </div>

                <div className="flex justify-start">
                  <button
                    type="submit"
                    disabled={uploadIsLoading}
                    className="btn-loader p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                  >
                    Upload Image
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>

        <div className="my-14">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={({ image, ...values }) => {
              mutate(values);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="flex flex-col">
                <p className=" text-dark text-[1.6rem] font-medium leading-normal">
                  Personal Information
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[20px] lg:gap-x-[30px] ">
                  <CustomInput
                    title="Firstname"
                    name="firstname"
                    onChange={handleChange}
                    value={values.firstname}
                    onBlur={handleBlur}
                  />
                  <CustomInput
                    title="Lastname"
                    name="lastname"
                    onChange={handleChange}
                    value={values.lastname}
                    onBlur={handleBlur}
                  />
                  <CustomInput
                    title="Email Address"
                    name="email"
                    value={values.email}
                    disabled
                  />
                  <CustomInput
                    title="Phone Number"
                    name="phone"
                    value={values.phone}
                    disabled
                  />
                  <CustomInput
                    title="Business Name"
                    name="business_name"
                    value={values.business_name}
                    disabled
                  />
                  <CustomSelect
                    title="Industry"
                    name="industry"
                    value={values.industry}
                    onChange={handleChange}
                    options={options}
                  />
                  <CustomSelect
                    title="Country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    options={cOptions}
                  />
                  <CustomInput
                    title="State"
                    name="state"
                    onChange={handleChange}
                    value={values.state}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="flex justify-end  my-24 lg:my-32">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn-loader p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Profile;
