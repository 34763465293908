import React, { useState } from "react";
import styles from "src/pages/Authentication/auth.module.css";
import OtpInput from "react-otp-input";
import { useMutation } from "@tanstack/react-query";
import { walletWithdraw } from "src/api";
import { renderAlert, renderNaira } from "src/utils/functions";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { NumericFormat } from "react-number-format";

const Withdrawal = ({ setIsWithdrawalModalOpen, walletBanlance, refetch }) => {
  const [pin, setPin] = useState("");
  const [step, setStep] = useState(1);
  const [errMsg, setErrMsg] = useState(false);

  const initialValues = {
    amount: "",
    pin: "",
  };

  const validationSchemaOne = yup.object().shape({
    amount: yup
      .number()
      .required("Amount is required")
      .min(4, "Value must be greater than four digits"),
  });

  const validationSchemaTwo = yup.object().shape({
    pin: yup
      .string()
      .matches(/^\d{4}$/, "Transaction PIN must be 4 digits")
      .required("Transaction PIN is required"),
  });

  const handleSubmit = (values) => {
    if (step === 1) {
      validationSchemaOne.validate(values).then(() => {
        setStep(2);
      });
    } else {
      validationSchemaTwo.validate(values).then(() => {
        if (walletBanlance < values?.amount) {
          setErrMsg(true);
        } else {
          setErrMsg(false);
          mutate(values);
        }
      });
    }
  };

  const { mutate, isLoading } = useMutation(walletWithdraw, {
    onSuccess: (res) => {
      if (res?.status === "success") {
        refetch();
        renderAlert({
          msg: res?.message,
          title: "Success",
          type: res.status,
        }).then(() => {
          setIsWithdrawalModalOpen(false);
        });
      } else {
        renderAlert({
          msg: res?.message,
          title: "Error",
          type: "error",
        });
      }
    },
  });

  return (
    <>
      <div className="">
        <div className="bg-lightPrimary text-center text-primary text-[1.8rem] p-8 mb-10 rounded-[5px] ">
          {renderNaira(walletBanlance)}
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={
            step === 1 ? validationSchemaOne : validationSchemaTwo
          }
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {step === 1 && (
                <div>
                  <p className={styles.otpLabel}>Enter Amount</p>
                  <NumericFormat
                    value={values.amount}
                    thousandSeparator=","
                    className="border p-5 rounded-[5px] w-full"
                    placeholder="Enter amount here"
                    onValueChange={(values) => {
                      setErrMsg(false);
                      setFieldValue("amount", values.value);
                    }}
                  />
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="text-red-500 text-[1.2rem] my-3"
                  />
                </div>
              )}

              {step === 2 && (
                <div>
                  <p className={styles.otpLabel}>Enter Payment PIN</p>
                  <OtpInput
                    value={pin}
                    onChange={(value) => {
                      setErrMsg(false);
                      setPin(value);
                      setFieldValue("pin", value);
                    }}
                    inputType="tel"
                    numInputs={4}
                    containerStyle={styles.otpContainer}
                    inputStyle={styles.otpInput}
                    renderInput={(props) => <input {...props} />}
                  />
                  <ErrorMessage
                    name="pin"
                    component={"div"}
                    className="text-red-500 text-[1.2rem] my-3"
                  />
                </div>
              )}

              {errMsg && (
                <div className="bg-red-400 text-white text-[1.2rem] my-5 p-3 rounded-[5px]">
                  Insufficient Funds
                </div>
              )}
              <div className="flex justify-between gap-10 mt-10">
                {step > 1 && (
                  <button
                    disabled={isLoading}
                    type="button"
                    onClick={() => setStep((prevStep) => prevStep - 1)}
                    className="w-full p-[15px] text-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center border-primary border text-[1.4rem] font-medium leading-none my-[10px]"
                  >
                    Previous
                  </button>
                )}
                <button
                  disabled={isLoading}
                  type="submit"
                  className={`w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px] 
                        ${isLoading ? " btn-loader" : ""}`}
                >
                  {step === 2 ? "Withdraw" : "Next"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Withdrawal;
