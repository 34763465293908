import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";

const FileDropZone = ({
  maxSize,
  acceptedTypes,
  onFileUpload,
  initialFile,
}) => {
  const [uploadedFile, setUploadedFile] = useState(initialFile || null);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isAcceptedFileError, setIsAcceptedFileError] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles, files) => {
      const validFiles = acceptedFiles.filter((file) =>
        acceptedTypes?.includes(file.type)
      );

      if (validFiles.length > 0) {
        setIsAcceptedFileError(false);
        setUploadedFile(validFiles[0]);
        if (onFileUpload) {
          onFileUpload(validFiles[0]);
        }
      } else {
        setIsAcceptedFileError(true);
      }

      setRejectedFiles(files.filter((file) => !validFiles.includes(file)));
    },
    [acceptedTypes, onFileUpload]
  );

  const handleDelete = () => {
    onFileUpload(null);
    setUploadedFile(null);
    setRejectedFiles([]);
    setIsAcceptedFileError(false);
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    minSize: 0,
    maxSize,
    noClick: !!uploadedFile,
  });

  const getFileExtension = (filename) => {
    const parts = filename.split("/");

    return parts[parts.length - 1];
  };

  const acceptedImageTypes = Array.isArray(acceptedTypes)
    ? acceptedTypes
    : acceptedTypes.split(",");

  return (
    <div className="my-[2rem]">
      <div
        {...getRootProps()}
        className="border-dashed border-2 mb-[3rem] cursor-pointer border-lightGray rounded p-8 h-[20rem] text-center flex flex-col justify-center items-center"
      >
        <input {...getInputProps()} />
        {!isDragActive && !uploadedFile && (
          <>
            <p className="text-lightGray text-[1.5rem]">
              Click here or drop a file to upload!
            </p>
            <p className="text-red-400 text-center text-[1.3rem] my-5">
              Accepted file formats includes:{" "}
              {acceptedImageTypes
                ?.map((type) => getFileExtension(type))
                .join(", ")}
              <br /> and Max allowed size is{" "}
              {parseFloat(maxSize / 1000000).toFixed(0)}MB
            </p>
          </>
        )}

        {uploadedFile && (
          <div className="flex items-center justify-between gap-4 overflow-auto">
            {typeof uploadedFile === "string" ? (
              <img
                src={uploadedFile}
                alt="Uploaded File"
                style={{ maxWidth: "150px", objectFit: "contain" }}
                // className="object-contain"
              />
            ) : (
              <img
                src={URL.createObjectURL(uploadedFile)}
                alt={uploadedFile.name}
                style={{ maxWidth: "150px", objectFit: "contain" }}
                className="object-contain"
              />
            )}
          </div>
        )}
      </div>
      {uploadedFile && (
        <>
          <div className="flex items-center justify-between gap-4 flex-wrap">
            {typeof uploadedFile === "string" ? (
              <p className="text-left text-lightGray text-[1.5rem] font-medium">
                {uploadedFile.split("/").pop()}
              </p>
            ) : (
              <p className="text-left text-lightGray text-[1.5rem] font-medium">
                {uploadedFile?.name}
              </p>
            )}
            <MdDelete
              className="text-lightGray cursor-pointer hover:text-red-400"
              size="2rem"
              onClick={handleDelete}
            />
          </div>
        </>
      )}
      {isAcceptedFileError && (
        <div className="text-red-400 text-[1.3rem] mt-2">
          Only{" "}
          {acceptedImageTypes?.map((type) => getFileExtension(type)).join(", ")}{" "}
          files are accepted
        </div>
      )}
      {rejectedFiles.length > 0 && (
        <div className="text-red-400 text-[1.3rem] mt-2">
          File is too large or not one of the accepted types{" "}
          {acceptedImageTypes?.map((type) => getFileExtension(type)).join(", ")}
        </div>
      )}
    </div>
  );
};

export default FileDropZone;
