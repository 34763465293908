import React, { createContext, useReducer, useContext, useEffect } from "react";
import {
  getFromStorage,
  setToStorage,
  renderToastMsg,
} from "src/utils/functions";

// Create a context
const CartContext = createContext();

// Initial state for the cart
const initialState = getFromStorage("cart") || {
  items: [],
  total: 0.0,
  totalWeight: 0.0,
};

const isNumber = (data) => parseFloat(data).toFixed(2);

const recalculateTotals = (items) => {
  let updatedTotal = 0.0;
  let updatedTotalWeight = 0.0;

  items.forEach((item) => {
    updatedTotal += parseFloat(item.product.price * item.quantity);
    updatedTotalWeight += parseFloat(item.product.unit_size * item.quantity);
  });

  return {
    total: isNumber(updatedTotal),
    totalWeight: isNumber(updatedTotalWeight),
  };
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItem = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );

      if (existingItem) {
        renderToastMsg(
          `Added 1 ${action.payload.product.productName} to the cart`,
          "success"
        );

        const updatedItems = state.items.map((item) =>
          item.product.id === action.payload.product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      } else {
        renderToastMsg(
          `Added 1 ${action.payload.product.productName} to the cart`,
          "success"
        );

        const updatedItems = [
          ...state.items,
          { product: action.payload.product, quantity: 1 },
        ];

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      }

      break;
    case "ADD_BUlK_ITEM_TO_CART":
      const existingBulkItem = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );

      const enteredQuantity = parseInt(action.payload.quantity, 10);

      if (existingBulkItem) {
        renderToastMsg(
          `Added ${enteredQuantity} ${action.payload.product.productName} to the cart`,
          "success"
        );

        const updatedItems = state.items.map((item) =>
          item.product.id === action.payload.product.id
            ? { ...item, quantity: enteredQuantity }
            : item
        );

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      } else {
        renderToastMsg(
          `Added ${enteredQuantity} ${action.payload.product.productName} to the cart`,
          "success"
        );

        const updatedItems = [
          ...state.items,
          {
            product: action.payload.product,
            quantity: enteredQuantity,
          },
        ];

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      }
      break;

    case "REMOVE_FROM_CART":
      const itemToRemove = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );

      if (itemToRemove && itemToRemove.quantity > 1) {
        renderToastMsg(
          `Removed 1 ${action.payload.product.productName} from the cart`,
          "success"
        );

        const updatedItems = state.items.map((item) =>
          item.product.id === action.payload.product.id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      } else {
        renderToastMsg(
          `Removed ${itemToRemove.quantity} ${action.payload.product.productName} from the cart`,
          "success"
        );

        const updatedItems = state.items.filter(
          (item) => item.product.id !== action.payload.product.id
        );

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      }
      break;

    case "REMOVE_ITEM":
      const removeFromCart = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );

      if (removeFromCart) {
        renderToastMsg(
          `Removed ${removeFromCart.quantity} ${action.payload.product.productName} from the cart`,
          "success"
        );

        const updatedItems = state.items.filter(
          (item) => item.product.id !== action.payload.product.id
        );

        recalculateTotals(updatedItems);

        return {
          ...state,
          items: updatedItems,
          ...recalculateTotals(updatedItems),
        };
      }
      break;

    case "CLEAR_CART":
      return {
        ...state,
        items: [],
        total: 0.0,
        totalWeight: 0.0,
      };
      break;

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    setToStorage("cart", cartState);
  }, [cartState]);

  return (
    <CartContext.Provider value={{ cartState, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
