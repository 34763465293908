import React from "react";
import {
  ConsignmentForm,
  DeliveryForm,
  PickupDeliveryForm,
} from "src/pages/Dashboard";

const RequestForm = ({ type, setStep }) => {
  return (
    <>
      {type === "consignment" ? (
        <ConsignmentForm setStep={setStep} />
      ) : type === "delivery" ? (
        <DeliveryForm setStep={setStep} />
      ) : (
        <PickupDeliveryForm setStep={setStep} />
      )}
    </>
  );
};

export default RequestForm;
