import { format, isValid, parse, parseISO } from "date-fns";
import Swal from "sweetalert2";
import { validFileExtensions } from "./constants";
// import * as XLSX from "xlsx";

export const renderCurrency = (
  amount,
  currency = "NGN",
  positive = true,
  showSign = false
) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    trailingZeroDisplay: "stripIfInteger",
    currencyDisplay: "narrowSymbol",
    signDisplay: showSign ? "always" : "auto",
  }).format(positive ? parseFloat(amount || 0) : parseFloat(amount || 0) * -1);
};

export const renderNumber = (amount, positive = true, showSign = false) => {
  return new Intl.NumberFormat("en-GB", {
    trailingZeroDisplay: "stripIfInteger",
    currencyDisplay: "narrowSymbol",
    signDisplay: showSign ? "always" : "auto",
  }).format(positive ? parseFloat(amount) : parseFloat(amount) * -1);
};

export const removeItemAtIndex = (array, indexToRemove) => {
  const copy = [...array];
  if (indexToRemove >= 0 && indexToRemove < copy.length) {
    copy.splice(indexToRemove, 1);
  }
  return copy;
};

export const setToStorage = (key, value) => {
  let storedValue = JSON.stringify(value);
  localStorage.setItem(key, storedValue);
};

export const getFromStorage = (key) => {
  let value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const renderAlert = ({
  msg = "",
  title = "",
  type = "info",
  confirmButtonText = "OK",
  confirmButtonColor = "#0059AC",
}) => {
  return new Promise((resolve) => {
    Swal.fire({
      text: msg,
      title: title,
      icon: type,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor,
    }).then((result) => {
      resolve(result);
    });
  });
};

export const renderToastMsg = (message, icon = "error", time = 3000) => {
  return new Promise((resolve) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: time,
      timerProgressBar: true,
      width: "auto",
    });

    Toast.fire({
      icon: icon,
      title: message,
    }).then(() => {
      resolve();
    });
  });
};

export const renderConfirmDialogue = (
  message = "Are you sure?",
  text = "You won't be able to revert this!",
  confirmButtonText = "Yes, delete it!"
) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: message,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "var(--primary-color)",
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

export const pluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

// export const exportToExcel = (excelData, name) => {
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.aoa_to_sheet(excelData);

//   XLSX.utils.book_append_sheet(workbook, worksheet, "sheet");

//   const excelBuffer = XLSX.write(workbook, {
//     bookType: "xlsx",
//     type: "array",
//   });
//   const blob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });

//   const url = URL.createObjectURL(blob);
//   const link = document.createElement("a");
//   link.setAttribute("href", url);
//   link.setAttribute("download", name);
//   link.style.display = "none";
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
//   URL.revokeObjectURL(url);
// };

export const convertToCurrency = (data) => {
  if (typeof data !== "number") {
    // Handle the case where 'data' is not a valid number
    return "Invalid Amount";
  }

  const number = Number(data);
  const currency = number.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  });

  return currency;
};

export const renderStatusColor = (status) => {
  // const lowerStatus = status.toLowerCase();
  switch (status) {
    case "delivered":
    case "successful":
    case "success":
    case "active":
    case "picked-up":
    case "picked up":
      return "text-[#1eb12d]";

    case "expired":
    case "inactive":
    case "cancelled":
    case "returned":
      return "text-[#FF4E4E]";
    case "pending":
    case "processing":
    case "in-transit":
      return "text-[#0059AC]";
    case "new":
    case "unfulfilled":
    case "rescheduled":
      return "text-[#FFA06A]";
    case "assigned":
      return "text-[#A020F0]";
    case "failed":
      return "text-[#800000]";
    default:
      return "";
  }
};

export const formatDate = (inputDateString) => {
  // Parse the input date string to a Date object
  const dateObject = parse(inputDateString, "M/d/yyyy", new Date());

  // Check if the parsed date is valid
  if (isValid(dateObject)) {
    // Format the date in the desired format
    const formattedDate = format(dateObject, "d MMMM yyyy");
    return formattedDate;
  } else {
    // Handle the case of an invalid date
    return "Invalid Date";
  }
};
export const formatTime = (inputDateString) => {
  const createdAt = new Date(inputDateString);

  const time = createdAt.toLocaleTimeString();
  return time;
};

export const generateUPC = () => {
  const randomDigits = Array.from({ length: 11 }, () =>
    Math.floor(Math.random() * 10)
  );

  const sumOdd = randomDigits
    .filter((_, index) => index % 2 === 0)
    .reduce((acc, digit) => acc + digit, 0);
  const sumEven = randomDigits
    .filter((_, index) => index % 2 === 1)
    .reduce((acc, digit) => acc + digit, 0);
  const total = sumOdd * 3 + sumEven;
  const checkDigit = (10 - (total % 10)) % 10;

  const upcCode = randomDigits.concat(checkDigit).join("");

  return upcCode;
};

export const renderStatusText = (type) => {
  switch (type) {
    case "credit":
      return "wallet top up";
    case "debit":
      return "withdrawal";
    default:
      return "";
  }
};

export const renderDate = (dateValue) => {
  const date = new Date(dateValue);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Month is zero-based, so we add 1
  const year = date.getUTCFullYear();

  // Ensure that day and month have leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  return formattedDate;
};

export const renderNaira = (currency) => {
  const currencyFormatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const formattedValue = currencyFormatter.format(currency);

  return formattedValue;
};

export const formatValidationErrors = (errors) => {
  if (typeof errors === "string") {
    return errors;
  }

  if (typeof errors === "object" && errors !== null) {
    return Object.entries(errors)
      .map(([key, messages]) => `${key}: ${messages.join("")}`)
      .join(" ");
  }

  return "Invalid error format";
};

export const filterDeliveryModesByTime = (modes, id) => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  if (id === 25) {
    if (currentHour >= 12) {
      return modes.filter(
        (mode) =>
          mode.value !== "same day" && mode.value !== "3 to 5 working days"
      );
    } else {
      return modes.filter((mode) => mode.value !== "3 to 5 working days");
    }
  } else {
    return modes.filter((mode) => mode.value === "3 to 5 working days");
  }
};

export const formatCustomDate = (dateString) => {
  // Parse the ISO date string to a Date object
  const date = parseISO(dateString);

  // Format the date as "d, MMM, yyyy, h:mm a"
  const formattedDate = format(date, "d, MMM, yyyy, h:mm a");

  return formattedDate;
};
