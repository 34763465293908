import React, { useState } from "react";
import styles from "src/pages/Authentication/auth.module.css";
import OtpInput from "react-otp-input";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createPaymentPin, initPaymentPin, verifyCreateOTP } from "src/api";
import { renderAlert, renderToastMsg } from "src/utils/functions";
import { CustomInput, CustomSelect } from "src/component";
import { secretQuestions } from "src/utils/constants";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";

const CreatePin = ({
  setIsPinModalOpen,
  setUser,
  refetch,
  setRunProfile,
  userId,
}) => {
  const [otp, setOtp] = useState("");
  const [pin, setPin] = useState("");
  const [step, setStep] = useState(0);
  const [run, setRun] = useState(false);

  const initialValues = {
    secret_question: "",
    secret_answer: "",
    transaction_pin: "",
    otp: "",
  };

  const validationSchemaOne = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d{6}$/, "OTP must be 6 digits")
      .required("OTP is required"),
  });

  const validationSchemaTwo = yup.object().shape({
    secret_answer: yup.string().required("Secret Answer is required"),
    secret_question: yup
      .string()
      .required("Secret Question is required")
      .oneOf(
        secretQuestions.map((question) => question.value),
        "You must choose a valid Secret Question"
      ),
  });

  const validationSchemaThree = yup.object().shape({
    transaction_pin: yup
      .string()
      .matches(/^\d{4}$/, "Transaction PIN must be 4 digits")
      .required("Transaction PIN is required"),
  });

  const handleSubmit = (values) => {
    if (step === 1) {
      validationSchemaOne.validate(values).then(() => {
        otpMutate({ id: userId, otp });
      });
    } else if (step === 2) {
      validationSchemaTwo.validate(values).then(() => {
        setStep(3);
      });
    } else {
      validationSchemaThree.validate(values).then(() => {
        const { secret_question, secret_answer, otp, transaction_pin } = values;
        mutate({
          secret_question,
          secret_answer: secret_answer.toLowerCase(),
          otp,
          transaction_pin,
        });
      });
    }
  };

  const { mutate: otpMutate, isLoading: otpIsLoading } = useMutation(
    verifyCreateOTP,
    {
      onSuccess: (res) => {
        if (res?.status === "success") {
          renderToastMsg(res?.message, "success").then(() => {
            setStep(2);
          });
        } else {
          renderAlert({
            msg: res?.message,
            title: "Error",
            type: "error",
          });
        }
      },
    }
  );

  const { mutate, isLoading } = useMutation(createPaymentPin, {
    onSuccess: (res) => {
      setRunProfile(true);
      refetch().then((res) => {
        setUser(res?.data?.data);
        setRunProfile(false);
      });
      renderAlert({
        msg: res?.message,
        title: "Success",
        type: res.status,
      }).then(() => {
        setIsPinModalOpen(false);
      });
    },
  });

  useQuery({
    queryKey: ["initPaymentPin"],
    queryFn: initPaymentPin,
    enabled: run,
    onSuccess: (res) => {
      renderToastMsg(res?.message, res?.status, 5000);
      setRun(false);
      setStep(1);
    },
  });

  return (
    <>
      <div className="">
        {step === 0 ? (
          <>
            <p className={styles.otpLabel}>Create your payment pin</p>

            <button
              disabled={run}
              type="button"
              onClick={() => setRun(true)}
              className={`w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px] 
                        ${run ? " btn-loader" : ""}`}
            >
              Create PIN
            </button>
          </>
        ) : (
          <>
            <div className="text-right text-primary text-[1.3rem] font-medium mb-10">
              Step {step} of 3
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={
                step === 1
                  ? validationSchemaOne
                  : step === 2
                  ? validationSchemaTwo
                  : validationSchemaThree
              }
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {step === 1 && (
                    <div>
                      <p className={styles.otpLabel}>
                        Enter OTP sent to your email below
                      </p>
                      <OtpInput
                        value={otp}
                        onChange={(value) => {
                          setOtp(value);
                          setFieldValue("otp", value);
                        }}
                        numInputs={6}
                        containerStyle={styles.otpContainer}
                        inputStyle={styles.otpInput}
                        renderInput={(props) => <input {...props} />}
                      />
                      <ErrorMessage
                        name="otp"
                        component={"div"}
                        className="text-red-500 text-[1.2rem] my-3"
                      />
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <CustomSelect
                        title="Choose a Secret Question"
                        name="secret_question"
                        value={values.secret_question}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={secretQuestions}
                      />

                      <CustomInput
                        title="Answer"
                        name="secret_answer"
                        onChange={handleChange}
                        value={values.secret_answer}
                        onBlur={handleBlur}
                      />
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      <p className={styles.otpLabel}>
                        Set your Transaction PIN
                      </p>
                      <OtpInput
                        value={pin}
                        onChange={(value) => {
                          setPin(value);
                          setFieldValue("transaction_pin", value);
                        }}
                        inputType="tel"
                        numInputs={4}
                        containerStyle={styles.otpContainer}
                        inputStyle={styles.otpInput}
                        renderInput={(props) => <input {...props} />}
                      />
                      <ErrorMessage
                        name="transaction_pin"
                        component={"div"}
                        className="text-red-500 text-[1.2rem] my-3"
                      />
                    </div>
                  )}

                  <div className="flex justify-between gap-10 mt-10">
                    {step > 2 && (
                      <button
                        disabled={isLoading || otpIsLoading}
                        type="button"
                        onClick={() => setStep((prevStep) => prevStep - 1)}
                        className="w-full p-[15px] text-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center border-primary border text-[1.4rem] font-medium leading-none my-[10px]"
                      >
                        Previous
                      </button>
                    )}
                    <button
                      disabled={isLoading || otpIsLoading}
                      type="submit"
                      className={`w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px] 
                        btn-loader`}
                    >
                      {step === 3
                        ? "Create"
                        : step === 1
                        ? "Verify OTP"
                        : "Next"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default CreatePin;
