import React, { useState } from "react";
import avatar from "src/assets/images/avatar.png";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { CustomPopup, Modal } from "src/component";
import { CreatePin, ViewNotification } from "src/modals";
import { useProSidebar } from "react-pro-sidebar";
import { HiMenuAlt2 } from "react-icons/hi";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getUnreadNotification,
  getUpdateNotification,
  getUserProfile,
} from "src/api";
import { Link } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";

const TopNav = () => {
  const { toggleSidebar } = useProSidebar();
  const { user, setUser } = useProtectedRoutesContext();
  const image = user?.image ? user?.image : avatar;
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationData, setNotificationData] = useState("");
  // const paymentPin = parseInt(user?.pin_set);
  const [runProfile, setRunProfile] = useState(false);

  // useEffect(() => {
  //   if (paymentPin === 0) {
  //     setIsPinModalOpen(true);
  //   }
  // }, [paymentPin]);

  const { refetch } = useQuery({
    queryKey: ["profile"],
    queryFn: getUserProfile,
    suspense: true,
    enabled: runProfile,
  });

  const { data, refetch: refetchNotification } = useQuery({
    queryKey: ["notification"],
    queryFn: () => getUnreadNotification(user?.id),
    suspense: true,
  });

  const { mutate } = useMutation(getUpdateNotification, {
    onSuccess: () => {
      refetchNotification();
    },
  });

  return (
    <>
      <div className=" bg-white border-b border-b-[#cfcfcf] flex items-center justify-between py-[1rem]  lg:py-[1.7rem]">
        <div
          className="cursor-pointer min-[1000px]:hidden"
          onClick={() => toggleSidebar()}
        >
          <HiMenuAlt2 size="2.5rem" className="text-lightText" />
        </div>
        <div className="text-primary text-[2rem] font-semibold  leading-none">
          Hello {user?.business_name}
          <small className="text-[1.3rem] text-darkGray font-normal ml-3 hidden md:inline">
            What would you like to do?
          </small>
        </div>
        <div className="flex items-center gap-6">
          <CustomPopup
            // onOpen={() => mutate({ notification_ids: getAllIds })}
            trigger={
              <button className="relative inline-block  cursor-pointer">
                <IoNotificationsOutline
                  size="2.5rem"
                  className="text-lightText"
                />
                {data?.data?.length > 0 && (
                  <span className="absolute top-1 right-[.4rem] h-[.8rem] w-[.8rem] bg-red-500 border border-red-500 rounded-full"></span>
                )}
              </button>
            }
            arrow={false}
            closeOnDocumentClick
            position={["bottom left"]}
            width={"350px"}
            on={["hover"]}
            childrenArray={[
              {
                text: (
                  <div className="flex flex-col w-full">
                    <div className="text-[#64748B]  border-b mb-5 text-[1.8rem] font-medium capitalize flex gap-2 justify-between">
                      <p className="font-semibold">Notifications </p>
                      <Link
                        to="profile/6"
                        className="text-primary my-1 text-[1.3rem] font-semibold capitalize"
                      >
                        View All
                      </Link>
                    </div>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((msg, i) => (
                        <div
                          key={i}
                          className="text-darkGray my-1 text-[1.4rem] font-medium p-3 cursor-pointer hover:bg-slate-100 rounded-[.5rem]"
                          onClick={() => {
                            setIsNotificationModalOpen(true);
                            setNotificationData(msg);
                            mutate({ notification_ids: [msg?.id] });
                          }}
                        >
                          {msg?.header}
                        </div>
                      ))
                    ) : (
                      <div className="text-darkGray my-2 text-[1.5rem] text-center font-medium capitalize">
                        No Notifications
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
          />

          <Link
            to="/profile/1"
            className="relative w-[40px] h-[40px] overflow-hidden rounded-full"
          >
            <img
              src={image}
              alt="user-avatar"
              className="rounded-full  border-gray-100 shadow-sm"
            />
          </Link>
        </div>
      </div>

      <Modal
        isOpen={isPinModalOpen}
        setIsOpen={setIsPinModalOpen}
        title="Change Payment PIN"
        size="sm"
        showCloseButton={false}
      >
        <CreatePin
          setIsPinModalOpen={setIsPinModalOpen}
          setRunProfile={setRunProfile}
          refetch={refetch}
          setUser={setUser}
          userId={user?.id}
        />
      </Modal>
      <Modal
        isOpen={isNotificationModalOpen}
        setIsOpen={setIsNotificationModalOpen}
        title="Notification"
        size="md"
      >
        <ViewNotification data={notificationData} />
      </Modal>
    </>
  );
};

export default TopNav;
