import React from "react";
import {
  MdOutlineCelebration,
  MdOutlineKeyboardArrowRight,
  MdOutlineNoAccounts,
} from "react-icons/md";
import { HiOutlineArchiveBox, HiOutlineUserCircle } from "react-icons/hi2";
import { VerticalTabs } from "src/component";
import { CiWallet } from "react-icons/ci";

import KYC from "./KYC";
import Profile from "./Profile";
import Referral from "./Referral";
import BankDetails from "./BankDetails";
import Address from "./Address";
import { PiAddressBookLight, PiLockKeyLight } from "react-icons/pi";
import TransactionPIN from "./TransactionPIN";
import DeleteAccount from "./DeleteAccount";
import Notifications from "./Notifications";
import { IoNotificationsOutline } from "react-icons/io5";

const Index = () => {
  const Tab = ({ icon, label }) => (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-[1rem]">
        {icon}
        <div className="text-[1.4rem] font-normal leading-normal ">{label}</div>
      </div>
      <MdOutlineKeyboardArrowRight size="2.5rem" />
    </div>
  );

  const tabs = [
    {
      label: "Edit Profile",
      icon: <HiOutlineUserCircle size="2.5rem" />,
      content: <Profile />,
    },
    {
      label: "Update KYC",
      icon: <HiOutlineArchiveBox size="2.5rem" />,
      content: <KYC />,
    },
    {
      label: "My Addresses",
      icon: <PiAddressBookLight size="2.5rem" />,
      content: <Address />,
    },
    {
      label: "Bank Settings",
      icon: <CiWallet size="2.5rem" />,
      content: <BankDetails />,
    },
    {
      label: "Change Password & PIN",
      icon: <PiLockKeyLight size="2.5rem" />,
      content: <TransactionPIN />,
    },

    // {
    //   label: "Help & Support",
    //   icon: <PiHeadsetLight size="2.5rem" />,
    //   content: "",
    // },
    {
      label: "Notifcations",
      icon: <IoNotificationsOutline size="2.5rem" />,
      content: <Notifications />,
    },

    {
      label: "Refer Friends",
      icon: <MdOutlineCelebration size="2.5rem" />,
      content: <Referral />,
    },

    {
      label: "Account Deletion",
      icon: <MdOutlineNoAccounts size="2.5rem" />,
      content: <DeleteAccount />,
    },
  ];

  return (
    <>
      <div className="text-primary text-[2rem] font-semibold leading-none mb-[30px]">
        My Profile
      </div>

      <div>
        <VerticalTabs
          url="/profile"
          tabs={tabs.map(({ label, icon, content }) => ({
            label: <Tab label={label} icon={icon} />,
            content,
          }))}
        />
      </div>
    </>
  );
};

export default Index;
