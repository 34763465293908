import React, { useState } from "react";
import { Loader, Modal } from "src/component";
import AddAdressDetails from "src/modals/profile/AddAddressDetails";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteAddress, getAddress } from "src/api";
import {
  renderAlert,
  renderConfirmDialogue,
  renderToastMsg,
} from "src/utils/functions";
import { AiOutlineDelete } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";

const Address = () => {
  const [address, setAddress] = useState(false);
  const [addressData, setAddressData] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["address"],
    queryFn: getAddress,
    suspense: true,
  });

  const { mutate, isLoading } = useMutation(deleteAddress, {
    onSuccess: (res) => {
      renderAlert({
        title: "Success",
        msg: res?.message,
        type: res?.status,
      });
      refetch();
    },
    onError: (error) => {
      renderToastMsg(error?.error);
    },
  });

  return (
    <>
      <div className=" border-b border-gray-200">
        <p className="py-[2rem] px-[5rem] text-lightText text-[2rem] font-semibold">
          My Addresses
        </p>
      </div>
      <div className="my-20  px-[5rem]">
        {isLoading ? (
          <Loader spin />
        ) : data?.data?.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 ">
            {data?.data?.map((value, index) => (
              <AddressBox
                key={index}
                data={value}
                mutate={mutate}
                setAddress={setAddress}
                setAddressData={setAddressData}
              />
            ))}
          </div>
        ) : (
          <p className="py-[2rem] px-[5rem] text-lightText text-center text-[1.6rem] font-medium">
            No Address Found
          </p>
        )}

        {data?.data?.length <= 4 && (
          <div className="flex justify-center my-[3rem]">
            <button
              type="button"
              onClick={() => {
                setAddress(true);
                setAddressData();
              }}
              className=" p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium my-[10px]"
            >
              Add New Address
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={address}
        setIsOpen={setAddress}
        size="sm"
        title={
          addressData ? "Update Address Information" : "Add Address Information"
        }
      >
        <AddAdressDetails
          refetch={refetch}
          setAddress={setAddress}
          addressData={addressData}
        />
      </Modal>
    </>
  );
};

export default Address;

const AddressBox = ({ data, mutate, setAddress, setAddressData }) => {
  const handleDelete = (id) => {
    renderConfirmDialogue().then((confirmed) => {
      if (confirmed) {
        mutate({ id });
      }
    });
  };

  return (
    <div className="address-box  py-10 px-12  relative rounded-xl">
      <div className="text-[1.5rem] text-lightText  font-medium mb-10 leading-[2.5rem] ">
        <p className="mb-3">Full Address: {data?.address}</p>
        <p>Alias: {data?.alias}</p>
      </div>

      <div className="flex mt-[2rem] gap-4 justify-end absolute bottom-[20px] right-[20px]">
        <TbEdit
          size="2rem"
          className="text-primary cursor-pointer"
          onClick={() => {
            setAddressData(data);
            setAddress(true);
          }}
        />
        <AiOutlineDelete
          size="2rem"
          className="text-red-400 cursor-pointer"
          onClick={() => handleDelete(data?.id)}
        />
      </div>
    </div>
  );
};
