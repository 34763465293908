import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ProSidebarProvider } from "react-pro-sidebar";

const root = ReactDOM.createRoot(document.getElementById("root"));

const listOfCodeToNotRepeat = [500, 404];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (_, error) => !listOfCodeToNotRepeat.includes(error?.cause),
    },
  },
});

root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
  </React.StrictMode>
);
