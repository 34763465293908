import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import AuthLayout from "./pages/Authentication/layout/AuthLayout";
import ErrorPage from "./pages/ErrorPage";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  CreateAccount,
  OTP,
} from "./pages/Authentication";
import MainLayout, { InventoryLayout } from "./pages/Dashboard/layout";

import {
  Dashboard,
  Inventory,
  Request,
  Tracker,
  Wallet,
  ViewProduct,
  Product,
  RequestDetails,
  CreateRequests,
  EditRequest,
} from "./pages/Dashboard";
import Profile from "./pages/Dashboard/Profile";
import ProtectedRoutesProvider from "./context/ProtectedRoutes";
import { CartProvider } from "./context/CartContext";
import { Suspense } from "react";
import { Loader } from "./component";
import { EditCartProvider } from "./context/EditCartContext";
// import { useProtectedRoutesContext } from "./context/ProtectedRoutes";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <Login />,
        },
        {
          path: "/register",
          element: <CreateAccount />,
        },
        {
          path: "/forget-password",
          element: <ForgotPassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/otp",
          element: <OTP />,
        },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/profile/:tabId",
          element: <Profile />,
        },
        {
          path: "/inventory",
          element: <InventoryLayout />,
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Inventory />,
            },
            {
              path: "product",
              element: <Product />,
            },
            {
              path: ":productId",
              element: <ViewProduct />,
            },
          ],
        },
        {
          path: "/wallet",
          element: <Wallet />,
        },
        {
          path: "/tracker",
          element: <Tracker />,
        },
        {
          path: "/requests",
          element: <Request />,
        },
        {
          path: "/requests/:requestId",
          element: <RequestDetails />,
        },
        {
          path: "/requests/create-request/:requestType",
          element: <CreateRequests />,
        },
        {
          path: "/requests/edit/:requestType/:requestId",
          element: <EditRequest />,
        },
      ],
    },
  ]);

  return (
    <Suspense fallback={<Loader spin />}>
      <ProtectedRoutesProvider>
        <CartProvider>
          <EditCartProvider>
            <RouterProvider router={router} />
          </EditCartProvider>
        </CartProvider>
      </ProtectedRoutesProvider>
    </Suspense>
  );
}

export default App;
