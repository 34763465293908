import React from "react";
import { useMutation } from "@tanstack/react-query";
import { deleteAccount } from "src/api";
import { renderConfirmDialogue, renderToastMsg } from "src/utils/functions";

const DeleteAccount = () => {
  const { mutate, isLoading } = useMutation(deleteAccount, {
    onSuccess: (res) => {
      renderToastMsg(res?.message, "success");
      localStorage.clear();
      window.location.reload();
    },
    onError: (error) => {
      renderToastMsg(error?.message);
    },
  });

  return (
    <>
      <div className=" border-b border-gray-200">
        <p className="py-[2rem]  px-[2rem] md:px-[5rem] text-lightText text-[2rem] font-semibold">
          Account Deletion
        </p>
      </div>

      <div className=" px-[2rem] md:px-[5rem]">
        <div className="my-[4rem]">
          <p className=" py-5 text-[1.5rem] leading-[2.6rem] text-lightText">
            You are attempting to delete your account on Sendforce. Please note
            that this action cannot be reversed when it is completed. Also note
            that all your products, orders and wallet balance will also be
            deleted. If you wish to proceed with this action click on the
            "Proceed with Account Deletion" button.
          </p>

          <div className="flex justify-start my-[2rem]">
            <button
              type="button"
              onClick={() => {
                renderConfirmDialogue(
                  "Are you sure",
                  "Are you sure delete this account?",
                  "Delete"
                ).then((confirmed) => {
                  if (confirmed) {
                    mutate();
                  }
                });
              }}
              disabled={isLoading}
              className=" p-[15px] bg-[#f04444] btn-loader-red rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium my-[10px]"
            >
              Proceed with Account Deletion
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
