import React, { useState } from "react";
import { Formik } from "formik";
import { CustomInput, GooglePlaceInput } from "../../component";
import * as yup from "yup";
import { addAddress, updateAddress } from "src/api";
import { useMutation } from "@tanstack/react-query";
import { renderAlert } from "src/utils/functions";

const AddAdressDetails = ({ refetch, setAddress, addressData }) => {
  const [value, setValue] = useState(
    addressData?.address
      ? { value: addressData?.address, label: addressData?.address }
      : ""
  );
  const initialValues = {
    alias: addressData?.alias ?? "",
  };

  const validationSchema = yup.object().shape({
    address: yup.string().required("Please provide Full Address"),
    alias: yup.string().required("Please provide Alias"),
  });

  const { mutate, isLoading } = useMutation(addAddress, {
    onSuccess: (res) => {
      renderAlert({
        title: "Success",
        msg: res?.message,
        type: res?.status,
      });
      refetch();
      setAddress(false);
    },
  });
  const { mutate: updateMutate, isLoading: isLoadingUpdate } = useMutation(
    updateAddress,
    {
      onSuccess: (res) => {
        renderAlert({
          title: "Success",
          msg: res?.message,
          type: res?.status,
        });
        refetch();
        setAddress(false);
      },
    }
  );

  const isDisabled = addressData ? isLoadingUpdate : isLoading;

  return (
    <>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            addressData
              ? updateMutate({
                  id: addressData?.id,
                  address: values?.address,
                  alias: values?.alias,
                })
              : mutate(values);
          }}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className=" ">
                <div>
                  <GooglePlaceInput
                    title="Full Address"
                    name="address"
                    validate={true}
                    value={value}
                    onChange={(values) => {
                      setValue(values);
                      setFieldValue("address", values?.label);
                    }}
                    placeholder="Search Address"
                  />
                </div>

                <CustomInput
                  title="Alias"
                  name="alias"
                  onChange={handleChange}
                  value={values.alias}
                  onBlur={handleBlur}
                />
              </div>

              <div className="flex justify-center my-4 ">
                <button
                  type="submit"
                  className="w-1/2 btn-loader p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                  disabled={isDisabled}
                >
                  {addressData ? "Update Address" : "Add Address"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddAdressDetails;
