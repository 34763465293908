import axios from "axios";
import {
  formatValidationErrors,
  getFromStorage,
  renderToastMsg,
  // setToStorage,
} from "src/utils/functions";

const requestUrl = () => {
  var url = window.origin;
  if (
    url?.includes("http://localhost") ||
    url?.includes("https://staging.merchant.sendforceng.com")
  ) {
    return process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCAL_BASEURL}`
      : `${process.env.REACT_APP_PROD_BASEURL}`;
  } else {
    return process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCAL_BASEURL_LIVE}`
      : `${process.env.REACT_APP_PROD_BASEURL_LIVE}`;
  }
};

const authApi = axios.create({
  baseURL: requestUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
  withCredentials: true,
});

// authApi.interceptors.response.use(
//   (response) => response.data,
//   (error) => {
//     if (error.response.status === 401) {
//       if (error.response.data.message === "Not verified") {
//         renderToastMsg(error.response.data.message).then(() => {
//           console.log("ldlsl", error.response);
//         });
//       } else {
//         renderToastMsg(error.response.data.message).then(() => {
//           localStorage.clear();
//         });
//       }
//     } else if (error.response.status === 503) {
//       renderToastMsg(error.response.data.message, "success");
//     } else {
//       renderToastMsg(
//         formatValidationErrors(error.response.data.message),
//         "error"
//       );
//       throw new Error(error.response.data.message, {
//         cause: error.response.status,
//       });
//     }
//   }
// );

authApi.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response.status === 401) {
      renderToastMsg(error.response.data.message).then(() => {
        localStorage.clear();
      });
    } else if (error.response.status === 503) {
      renderToastMsg(error.response.data.message, "success");
    } else {
      renderToastMsg(
        formatValidationErrors(error.response.data.message),
        "error"
      );
    }
    // Ensure the error is propagated by returning a rejected promise
    return Promise.reject(error?.response?.data, {
      cause: error.response.status,
    });
  }
);

//* Sign in
export const signin = (data) => {
  return authApi.post("/auth/login", data);
};

//* Sign up
export const register = (data) => {
  return authApi.post("/auth/register", data);
};

//* Verify Otp
export const verifyOtp = ({ id, data }) => {
  if (id === "reset") {
    return authApi.post("/auth/verify-reset", data);
  } else {
    return authApi.post("/auth/verify-otp", data);
  }
};

export const verifyAccountOtp = (data) => {
  return authApi.post("/auth/verify-account-otp", { email: data });
};

//* Resend Otp
export const resendOTP = (data) => {
  return authApi.post("/auth/resend-otp", data);
};

//* Reset Password
export const resetPassword = ({ data }) => {
  return authApi.post("/auth/resetPassword", data);
};

//* Forgot Password
export const forgotPassword = (data) => {
  return authApi.post("/auth/forgotPassword", data);
};

const api = axios.create({
  baseURL: requestUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
  return config;
}, null);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response.status === 401) {
      renderToastMsg(error.response.data.message).then(() => {
        localStorage.clear();
        window.location.reload();
      });
    } else if (error.response.status === 503) {
      renderToastMsg(error.response.data.message, "success");
    } else {
      renderToastMsg(error.response.data.message);
      throw new Error(error.response.data.message, {
        cause: error.response.status,
      });
    }
  }
);

//? Inventory
//* Get all Product
export const getProducts = (params) => {
  return api.get("/merchant/products", { params });
};

//*Get one Product Details
export const getProductDetails = (uuid) => {
  return api.get(`/merchant/product/view/${uuid}`);
};

//* Create Products
export const CreateProducts = (data) => {
  return api.post("/merchant/product/create", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//* create a product variant
export const CreateProductVariant = (data) => {
  return api.post("/merchant/product_variant/create", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//* edit a product variant
export const editProduct = (data) => {
  return api.post("/merchant/product/update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
//* edit a product variant
export const editProductVariant = (data) => {
  return api.post("/merchant/product_variant/update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//* archive a product
export const archiveProduct = (id) => {
  return api.post(`/merchant/product/archive`, id);
};

//* Reactivate a product
export const reactivateProduct = (id) => {
  return api.post(`/merchant/product/reactivate`, id);
};

//* delete a product variant
export const deleteProductVariant = (id) => {
  return api.delete(`/merchant/product_variant/${id}`);
};

//* delete a product
export const deleteProduct = (id) => {
  return api.delete(`/merchant/product/${id}`);
};

//* update product inventory
export const updateVaraiantInventory = (data) => {
  return api.post(`/merchant/product_variant/updateInventory`, data);
};

//? end Inventory

//? Request

//* get all request
export const getRequest = (params) => {
  return api.get("/merchant/requests/allmine", { params });
};
export const trackRequest = (uuid) => {
  return api.get(`/merchant/request/track/${uuid}`);
};

//* get all request
export const getOneRequest = (id) => {
  return api.get(`/merchant/request/view/${id}`);
};

//*
export const getRequestFees = (data) => {
  if (data?.type === "pickup") {
    const { type, ...restData } = data;
    return api.post(`/merchant/request/get_pickup_fee`, restData);
  } else if (data?.type === "delivery") {
    const { type, ...restData } = data;
    const deliveryFeePromise = api.post(
      `/merchant/request/get_delivery_fee`,
      restData
    );
    const expectedDatePromise = expectedDeliveryDate({
      delivery_mode: restData?.delivery_mode,
    });
    return Promise.all([deliveryFeePromise, expectedDatePromise]).then(
      (results) => {
        const [deliveryFeeResponse, expectedDateRes] = results;

        return {
          deliveryFee: deliveryFeeResponse,
          deliveryDate: expectedDateRes,
        };
      }
    );
  } else {
    const { weight, delivery_location_id, pickup_location_id, delivery_mode } =
      data;

    const pickupFeePromise = api.post(`/merchant/request/get_pickup_fee`, {
      weight,
      pickup_location_id,
    });

    const expectedDatePromise = expectedDeliveryDate({
      delivery_mode: delivery_mode,
    });

    const deliveryFeePromise = api.post(
      `/merchant/request/get_pickup_delivery_fee`,
      {
        weight,
        pickup_location_id,
        delivery_location_id,
        delivery_mode,
      }
    );
    return Promise.all([
      pickupFeePromise,
      deliveryFeePromise,
      expectedDatePromise,
    ]).then((results) => {
      const [pickupFeeResponse, deliveryFeeResponse, expectedDateRes] = results;

      return {
        pickupFee: pickupFeeResponse,
        deliveryFee: deliveryFeeResponse,
        deliveryDate: expectedDateRes,
      };
    });
  }
};

//* create consignment request
export const createConsignmentRequest = (data) => {
  return api.post(`/merchant/request/create/consignment`, data);
};

//* create delivery request
export const createDeliveryRequest = (data) => {
  return api.post(`/merchant/request/create/delivery`, data);
};

//* create pickupdelivery request
export const createPickupDeliveryRequest = (data) => {
  return api.post(`/merchant/request/create/pickupdelivery`, data);
};

//* create cancel request
export const cancelRequest = (data) => {
  return api.post(`/merchant/request/cancel`, data);
};

//? end request

//? Get Dashboard Data
export const getDashboardData = () => {
  return api.get(`/merchant/dashboard`);
};

//? Profile
//* Get User Profile
export const getUserProfile = () => {
  return api.get(`/merchant/profile`);
};

//* Update Profile
export const updateProfileImage = (data) => {
  return api.post("merchant/profile/update_image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//* Update Profile
export const updateProfile = (data) => {
  return api.post(`/merchant/profile/update`, data);
};

//* Upload Kyc
export const uploadKyc = ({ ...data }) => {
  return api.post("/merchant/kyc", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//* Get Kyc
export const getKyc = () => {
  return api.get(`/merchant/kyc`);
};

//* Upload an Address
export const addAddress = (data) => {
  return api.post(`/merchant/address`, data);
};

//* Update an Address
export const updateAddress = (data) => {
  return api.post(`/merchant/address/update`, data);
};

//* delete a address
export const deleteAddress = (id) => {
  return api.post(`/merchant/address/delete`, id);
};

//* Get Address
export const getAddress = () => {
  return api.get(`/merchant/address`);
};

//* change password
export const changePassword = (data) => {
  return api.post("/merchant/profile/change_password", data);
};

//* add bank details
export const addBankDetails = (data) => {
  return api.post("/merchant/profile/set_bank_details", data);
};

//* Get bank details
export const getBankDetails = () => {
  return api.get(`/merchant/profile/get_bank_details`);
};

//* Get secret question
export const getSecretQuestion = () => {
  return api.get(`/merchant/profile/get_secret_question`);
};

//? end Profile

//? wallet

//* Get Wallet Transaction History
export const GetWalletTransactions = () => {
  return api.get(`/merchant/wallet`);
};

//* Get Wallet Transaction History
export const verifyPaymentRef = (transRef) => {
  return api.get(`/merchant/wallet/verify?transRef=${transRef}`);
};

//* Fund Wallet
export const fundWallet = (data) => {
  return api.post(`/merchant/wallet`, data);
};

//* Wallet Withdraw
export const walletWithdraw = (data) => {
  return api.post(`/merchant/wallet/withdraw`, data);
};

//? end wallet

//? Transaction PIN

//* init Payment Pin
export const initPaymentPin = () => {
  return api.get(`/merchant/init_payment_pin`);
};

//* init Reset Payment Pin
export const initResetPaymentPin = () => {
  return api.get(`/merchant/init_reset_pin`);
};

//* Create Payment Pin
export const createPaymentPin = (data) => {
  return api.post(`/merchant/create_payment_pin`, data);
};

//* verify Payment Pin
export const verifyPaymentPIN = (data) => {
  return api.post(`/merchant/verify_payment_pin`, data);
};

//* reset Payment Pin
export const resetPaymentPin = (data) => {
  return api.post(`/merchant/reset_pin`, data);
};

//* reset Payment Pin
export const verifyCreateOTP = (data) => {
  return api.post("/merchant/verify_otp_for_pin", data);
};

//? not protected endpoints

//*get notification
export const getNotification = (id) => {
  return api.get("/gen/notifications/all/" + id);
};

//*get unread notification
export const getUnreadNotification = (id) => {
  return api.get("/gen/notifications/get_unread/" + id);
};

//*update notification status
export const getUpdateNotification = (data) => {
  return api.post("/gen/notifications/mark_as_read", data);
};

//*get FulfilmentCenter
export const getFulfilmentCenter = () => {
  return api.get("/gen/list_warehouses");
};
//*get Categories
export const getCategories = () => {
  return api.get("/gen/categories");
};

//*get Banks
export const getBanks = () => {
  return api.get("/gen/banks");
};

//*verify Account Number
export const verifyAccountNumber = (params) => {
  return api.get("/gen/verify-bank", { params });
};

//* get State
export const getStates = () => {
  return api.get("/gen/get_states");
};

//* get State Location
export const getLocationByState = (id) => {
  return api.get(`/gen/get_locations_by_state/${id}`);
};

//* get delivery mode
export const getDeliveryMode = (id) => {
  return api.get(`/gen/delivery_modes`);
};

//* Expected Delivery Date
export const expectedDeliveryDate = (data) => {
  return api.post("/merchant/request/edd", data);
};

//* Delete Account
export const deleteAccount = () => {
  return api.post("/merchant/profile/delete");
};
