import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { PiBankDuotone } from "react-icons/pi";
import { getBankDetails } from "src/api";
import { Modal } from "src/component";
import { AddBankDetails } from "src/modals";

const BankDetails = () => {
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);

  const { data: bankData, refetch } = useQuery({
    queryKey: ["bankDetails"],
    queryFn: getBankDetails,
    suspense: true,
  });

  return (
    <>
      <div className=" border-b border-gray-200">
        <p className="py-[2rem]  px-[2rem] md:px-[5rem] text-lightText text-[2rem] font-semibold">
          Bank Settings
        </p>
      </div>

      <div className=" px-[2rem] md:px-[5rem]">
        <div className="my-[2rem]">
          <p className="text-lightGray font-medium text-[1.6rem] ">
            For Withdrawal
          </p>

          <p className=" py-5 text-[1.5rem] text-lightGray">
            This is where funds in your wallet will be sent to when you initiate
            a withdrawal.
          </p>
        </div>
        {Array.isArray(bankData?.data) ? (
          <div
            className=" cursor-pointer px-20 py-20 min-h-[20rem] w-full md:w-[42rem] text-neutral-400  bg-white rounded-[10px] shadow border-[.8px]  border-[#CBCBCB] justify-center items-center gap-2.5 inline-flex hover:scale-[.98] hover:border-primary transition-border transform duration-300 ease-in-out hover:transform hover:text-primary"
            onClick={() => {
              setIsBankModalOpen(true);
            }}
          >
            <div className="justify-center items-center gap-2 flex">
              <div className="text-opacity-70 text-[1.6rem] font-medium">
                + Add Bank Account
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="px-10 py-10 min-h-[20rem] shadow rounded-[10px]  border-[1px]  border-[#69B7FF] justify-start gap-2.5 inline-flex ">
              <div className="justify-start  gap-10 flex-col flex">
                <div className="flex justify-start items-center gap-10">
                  <div className="  bg-[#EBF5FF] rounded-full p-[15px] ">
                    <PiBankDuotone color="var(--primary-color)" size="3rem" />
                  </div>
                  <div className="text-dark text-[1.6rem] font-medium">
                    {bankData?.data?.bank_name}
                  </div>
                </div>

                <div>
                  <div className="text-lightGray text-[1.6rem] font-medium mb-5">
                    <p>
                      Account Number:
                      <span className="ml-5">
                        {bankData?.data?.account_number}
                      </span>
                    </p>
                  </div>
                  <div className="text-lightGray text-[1.6rem] font-medium mb-5 flex gap-5 items-center">
                    <p>
                      Account Name:
                      <span className="ml-5">
                        {bankData?.data?.account_name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-[4rem]">
              <p className="text-primary text-[1.6rem] font-semibold">
                Looking to change your bank?
              </p>

              <p className=" py-5 text-[1.5rem] text-lightText">
                For security reasons, Please contact
                <span className="font-semibold mx-2">
                  Sendforce Support via the In-app chat
                </span>
                to change your bank
              </p>
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={isBankModalOpen}
        setIsOpen={setIsBankModalOpen}
        title="Add Bank Information"
        size="sm"
      >
        <AddBankDetails
          setIsBankModalOpen={setIsBankModalOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
};

export default BankDetails;
