import React, { useState } from "react";
import { Modal } from "src/component";
import { ChangePassword, ResetPin } from "src/modals";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const TransactionPIN = () => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const { user } = useProtectedRoutesContext();

  return (
    <>
      <div className=" border-b border-gray-200">
        <p className="py-[2rem] px-[5rem] text-lightText text-[2rem] font-semibold">
          Password & PIN
        </p>
      </div>

      <div className="flex gap-[5rem] justify-center my-20 flex-col  px-[5rem]">
        <div className="flex gap-[3rem] md:gap-[10rem] flex-col md:flex-row">
          <div className="md:w-[40%]">
            <p className="text-[1.8rem] text-lightText font-medium mb-2 leading-[2.5rem] ">
              Update Password
            </p>
            <p className="text-[1.3rem] text-lightGray ">
              Change your old password to a new one
            </p>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsPasswordModalOpen(true);
            }}
            className="text-[1.6rem] text-left  text-primary cursor-pointer font-medium mb-2 leading-[2.5rem] "
          >
            Change Password
          </button>
        </div>

        <div className="flex gap-[3rem] md:gap-[10rem] flex-col md:flex-row">
          <div className="md:w-[40%]">
            <p className="text-[1.8rem] text-lightText font-medium mb-2 leading-[2.5rem] ">
              Update PIN
            </p>
            <p className="text-[1.3rem] text-lightGray ">
              Change or reset your Sendforce PIN
            </p>
          </div>

          <button
            type="button"
            className="text-[1.6rem] text-primary cursor-pointer text-left font-medium mb-2 leading-[2.5rem] "
            onClick={() => {
              setIsPinModalOpen(true);
            }}
          >
            Change PIN
          </button>
        </div>
      </div>

      <Modal
        isOpen={isPinModalOpen}
        setIsOpen={setIsPinModalOpen}
        title="Change Payment PIN"
        size="sm"
      >
        <ResetPin setIsPinModalOpen={setIsPinModalOpen} userId={user?.id} />
      </Modal>
      <Modal
        isOpen={isPasswordModalOpen}
        setIsOpen={setIsPasswordModalOpen}
        title="Change Password"
        size="sm"
      >
        <ChangePassword setIsPasswordModalOpen={setIsPasswordModalOpen} />
      </Modal>
    </>
  );
};

export default TransactionPIN;
