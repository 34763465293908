import React from "react";
import { formatCustomDate } from "src/utils/functions";

const ViewNotification = ({ data }) => {
  return (
    <>
      <div className="">
        <p className="text-primary text-[1.3rem]">
          {formatCustomDate(data?.created_at)}
        </p>
        <p className="text-[1.5rem] border-b text-darkGray my-4 py-2">
          {data?.header}
        </p>
        <p className="text-[1.6rem] text-lightText my-[2rem]">
          {data?.content}
        </p>
      </div>
    </>
  );
};

export default ViewNotification;
