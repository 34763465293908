import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import TopNav from "./TopNav";
import styled from "styled-components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { Loader } from "src/component";

const Container = styled.div`
  display: flex;
  overflow: hidden;

  .wrapper {
    max-height: 100vh;
    min-height: 100vh;
    flex: 1;
    width: calc(100% - 250px);
    background-color: #ffffff;
    overflow-y: scroll;

    & > div {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .main {
      margin-top: 4rem;
    }
  }

  @media only screen and (max-width: 769px) {
    .wrapper {
      min-height: 100vh;
      flex: 1;
      width: 100%;
      & > div {
        padding-left: 4rem;
        padding-right: 4rem;
      }
      .main {
        margin-top: 2rem;
      }
    }
  }
`;

const MainLayout = () => {
  const { user, token } = useProtectedRoutesContext();

  if (!user || !token) return <Navigate to="/" />;

  return (
    <Container>
      <SideBar />
      <div className="wrapper">
        <TopNav />
        <div className="main">
          <Suspense fallback={<Loader spin />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </Container>
  );
};

export const InventoryLayout = () => {
  return <Outlet />;
};

export default MainLayout;
