import React from "react";
import logo from "src/assets/images/logo.png";
import { Formik } from "formik";
import * as yup from "yup";
import { CustomInput } from "src/component";
import { BannerLayout } from "./layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import { getFromStorage, renderToastMsg } from "src/utils/functions";
import { resetPassword } from "src/api";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    password1: yup
      .string()
      .required("Confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords don't match"),
  });

  const initialValues = {
    password: "",
    password1: "",
  };

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: (res) => {
      localStorage.clear();
      renderToastMsg(res.message, "success").then(() => {
        navigate("/");
      });
    },
    onError: (error) => {
      renderToastMsg(error.message);
    },
  });

  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 h-[100vh]">
          <BannerLayout />
          <div className="col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="w-[500px] mx-auto my-[50px] px-[50px] min-h-[80%] relative">
                <img src={logo} alt="sendforce logo" />
                <div className="my-[100px]">
                  <p className=" text-dark text-[2.4rem] font-bold leading-none mb-8">
                    Reset Password
                  </p>
                  <p className="w-[323px] text-lightGray text-[1.6rem] font-normal ">
                    Enter a new password
                  </p>

                  {/* form */}
                  <div className="my-[30px]">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) =>
                        mutate({
                          data: {
                            otp: getFromStorage("otp"),
                            email: getFromStorage("email"),
                            password: values.password,
                          },
                        })
                      }
                    >
                      {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <CustomInput
                            title="New Password"
                            name="password"
                            passwordToggle
                            onChange={handleChange}
                            value={values.password}
                            onBlur={handleBlur}
                          />
                          <CustomInput
                            title="Confirm New Password"
                            name="password1"
                            passwordToggle
                            onChange={handleChange}
                            value={values.password1}
                            onBlur={handleBlur}
                          />

                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn-loader w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                          >
                            Reset Password
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>

                  <div className="my-auto absolute text-center bottom-0 left-0 right-0">
                    <p className="text-lightGray  text-[1.4rem] font-medium leading-none">
                      sendforce logistics limited. All rights reserved.
                      <span className="ml-2">{new Date().getFullYear()}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
