import { ErrorMessage } from "formik";
import React from "react";
import Select from "react-select";

const SearchableSelect = ({
  title,
  name,
  options,
  validate = true,
  ...rest
}) => {
  const customStyles = {
    placeholder: (styles) => ({
      ...styles,
      color: "#667085",
      fontSize: "1.4rem",
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#fff" : "#667085",
      fontSize: "1.4rem",
      backgroundColor: state.isSelected ? "#0059ad" : "#fffff",
      "&:hover": {
        backgroundColor: "#0059ad",
        color: "#ffffff",
        cursor: "pointer",
      },
    }),
    control: (styles) => ({
      ...styles,
      fontSize: "1.4rem",
      color: "#667085",
      minHeight: "0",
      padding: "5px",

      border: "1px solid #b3b2b2",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      "&:focus": {
        borderColor: "#0059ad",
      },
    }),
  };

  return (
    <>
      <div className="my-5">
        <p className="mb-3 text-[1.6rem] text-lightGray font-normal">{title}</p>
        <Select
          isClearable
          options={options}
          name={name}
          styles={customStyles}
          {...rest}
        />
        {validate && (
          <ErrorMessage
            name={name}
            component="div"
            className="text-red-500 text-[1.2rem] my-3"
          />
        )}
      </div>
    </>
  );
};

export default SearchableSelect;
