import React from "react";
import logo from "src/assets/images/logo.png";
import { Formik } from "formik";
import * as yup from "yup";
import { CustomInput } from "src/component";
import { Link, useNavigate } from "react-router-dom";
import { BannerLayout } from "./layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import { renderToastMsg, setToStorage } from "src/utils/functions";
import { forgotPassword } from "src/api";

const ForgotPassord = () => {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email")
      .required("Please provide a valid email"),
  });

  const initialValues = {
    email: "",
  };

  const { mutate, isLoading } = useMutation(forgotPassword, {
    onSuccess: (data) => {
      renderToastMsg(data?.message, "success", 5000);
      navigate("/otp?reset=true");
    },
    onError: (error) => {
      renderToastMsg(error.message);
    },
  });

  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 h-[100vh]">
          <BannerLayout />
          <div className="col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2 ">
            <div className="flex flex-col items-center justify-center h-full  ">
              <div className="w-[500px] mx-auto my-[50px] px-[50px] relative min-h-[80%]  ">
                <img src={logo} alt="sendforce logo" />
                <div className="my-[100px]">
                  <p className=" text-dark text-[2.4rem] font-bold leading-none mb-8">
                    Forgot Password
                  </p>
                  <p className="w-[323px] text-lightGray text-[1.6rem] font-normal ">
                    Enter the email address you used to sign up to reset your
                    password
                  </p>

                  {/* form */}
                  <div className="my-[30px]">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        setToStorage("email", values.email);
                        mutate(values);
                      }}
                    >
                      {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <CustomInput
                            title="Email Address "
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                          />

                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn-loader w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                          >
                            Send OTP
                          </button>

                          <div className="text-center my-[20px] ">
                            <p className="text-lightGray text-[1.3rem] font-normal mb-[10px] leading-none">
                              Remember your password?
                              <Link
                                to="/"
                                className=" text-primary text-[1.5rem] font-medium leading-none ml-2"
                              >
                                Sign In
                              </Link>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="my-auto absolute text-center bottom-0 left-0 right-0">
                  <p className="text-lightGray  text-[1.4rem] font-medium leading-none">
                    sendforce logistics limited. All rights reserved.
                    <span className="ml-2">{new Date().getFullYear()}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassord;
