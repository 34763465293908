import React, { useState } from "react";
import logo from "src/assets/images/logo.png";
import { Formik } from "formik";
import * as yup from "yup";
import { CustomInput } from "src/component";
import { Link, useNavigate } from "react-router-dom";
import { BannerLayout } from "./layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import { signin, verifyAccountOtp } from "src/api";

import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { renderAlert, renderToastMsg, setToStorage } from "src/utils/functions";

const Login = () => {
  const { setUser, setToken } = useProtectedRoutesContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const signInSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email")
      .required("Please provide a valid email"),
    password: yup.string().required("Please provide password"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const { mutate, isLoading } = useMutation(signin, {
    onSuccess: (data) => {
      if (data) {
        setUser(data?.data);
        setToken(data?.access_token);
        renderToastMsg(data?.message, "success").then(() => {
          navigate("/dashboard");
        });
      }
    },
    onError: (err) => {
      if (err?.message === "Not verified") {
        mutateVerifyAcc(email);
      }
    },
  });

  const { mutate: mutateVerifyAcc, isLoading: verifyIsLoading } = useMutation(
    verifyAccountOtp,
    {
      onSuccess: (data) => {
        setToStorage("email", email);
        renderAlert({
          title: "Success",
          msg: data?.message,
          type: "success",
        }).then((result) => {
          result.isConfirmed
            ? navigate("/otp?c=true")
            : navigate("/otp?c=true");
        });
      },
    }
  );

  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 h-[100vh]">
          <BannerLayout />
          <div className="col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="w-[500px] mx-auto my-[50px] px-[50px] min-h-[80%] relative">
                <img src={logo} alt="sendforce logo" />
                <div className="my-[100px]">
                  <p className=" text-dark text-[2.4rem] font-bold leading-none mb-8">
                    Welcome Back
                  </p>
                  <p className="w-[323px] text-lightGray text-[1.6rem] font-normal ">
                    Fill your email address and password to continue
                  </p>

                  {/* form */}
                  <div className="my-[30px]">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      onSubmit={(values) => {
                        setEmail(values?.email);
                        mutate(values);
                      }}
                    >
                      {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <CustomInput
                            title="Email Address"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                          />
                          <CustomInput
                            title="Password"
                            name="password"
                            passwordToggle
                            onChange={handleChange}
                            value={values.password}
                            onBlur={handleBlur}
                          />
                          <div className="flex items-center justify-between mb-[20px] ">
                            <label className="flex items-center justify-start gap-2 cursor-pointer">
                              <input
                                type="checkbox"
                                name="isChecked"
                                className=" accent-primary focus:accent-primary hover:accent-primary"
                                checked={values.isChecked}
                              />

                              <div className="text-lightGray text-[1.4rem] font-normal leading-none">
                                Remember me
                              </div>
                            </label>
                            <Link
                              to="/forget-password"
                              className=" text-primary text-[1.5rem] font-medium leading-none m"
                            >
                              Forgot Password?
                            </Link>
                          </div>

                          <button
                            disabled={isLoading || verifyIsLoading}
                            type="submit"
                            className="w-full btn-loader p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                          >
                            Sign in
                          </button>
                          <div className="text-center my-[20px] ">
                            <p className="text-lightGray text-[1.3rem] font-normal mb-[10px] leading-none">
                              Don’t have an account?
                              <Link
                                to="/register"
                                className=" text-primary text-[1.5rem] font-medium leading-none ml-2"
                              >
                                Sign Up
                              </Link>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>

                  <div className="my-auto absolute text-center bottom-0 left-0 right-0">
                    <p className="text-lightGray  text-[1.4rem] font-medium leading-none">
                      sendforce logistics limited. All rights reserved.
                      <span className="ml-2">{new Date().getFullYear()}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
