import React, { createContext, useReducer, useContext, useEffect } from "react";
import {
  getFromStorage,
  setToStorage,
  renderToastMsg,
} from "src/utils/functions";

// Create a context
const EditCartContext = createContext();

// Initial state for the cart
const initialState = getFromStorage("editcart") || {
  items: [],
  total: 0.0,
  totalWeight: 0.0,
};

// Reducer function
const cartReducer = (state, action) => {
  const isNumber = (data) => {
    if (typeof data === "string") {
      return parseFloat(data).toFixed(2);
    } else {
      return parseFloat(data).toFixed(2);
    }
  };

  switch (action.type) {
    case "ADD_TO_CART":
      // Check if the product is already in the cart
      const existingItem = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );

      if (existingItem) {
        renderToastMsg(
          `Added 1 ${
            action.payload.product?.productName ??
            action.payload.product?.product_name
          } to the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) + parseFloat(action.payload.product.price)
          ).toFixed(2)
        );

        const updatedTotalWeight = isNumber(
          (
            parseFloat(state.totalWeight) +
            parseFloat(action.payload.product.unit_size)
          ).toFixed(2)
        );

        return {
          ...state,
          items: state.items.map((item) =>
            item.product.id === action.payload.product.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
          total: updatedTotal,
          totalWeight: updatedTotalWeight,
        };
      } else {
        renderToastMsg(
          `Added 1 ${
            action.payload.product?.productName ??
            action.payload.product?.product_name
          } to the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) + parseFloat(action.payload.product.price)
          ).toFixed(2)
        );

        const updatedTotalWeight = isNumber(
          (
            parseFloat(state.totalWeight) +
            parseFloat(action.payload.product.unit_size)
          ).toFixed(2)
        );

        return {
          ...state,
          items: [
            ...state.items,
            { product: action.payload.product, quantity: 1 },
          ],
          total: updatedTotal,
          totalWeight: updatedTotalWeight,
        };
      }

    case "REMOVE_FROM_CART":
      const itemToRemove = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );
      if (itemToRemove && itemToRemove.quantity > 1) {
        renderToastMsg(
          `Removed 1 ${
            action.payload.product?.productName ??
            action.payload.product?.product_name
          } from the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) - parseFloat(action.payload.product.price)
          ).toFixed(2)
        );

        const updatedTotalWeight = isNumber(
          (
            parseFloat(state.totalWeight) -
            parseFloat(action.payload.product.unit_size)
          ).toFixed(2)
        );

        return {
          ...state,
          items: state.items.map((item) =>
            item.product.id === action.payload.product.id
              ? { ...item, quantity: item.quantity - 1 }
              : item
          ),
          total: updatedTotal,
          totalWeight: updatedTotalWeight,
        };
      } else {
        renderToastMsg(
          `Removed ${itemToRemove.quantity} ${
            action.payload.product?.productName ??
            action.payload.product?.product_name
          } from the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) -
            parseFloat(action.payload.product.price * itemToRemove.quantity)
          ).toFixed(2)
        );

        const updatedTotalWeight = isNumber(
          (
            parseFloat(state.totalWeight) -
            parseFloat(action.payload.product.unit_size * itemToRemove.quantity)
          ).toFixed(2)
        );

        return {
          ...state,
          items: state.items.filter(
            (item) => item.product.id !== action.payload.product.id
          ),
          total: updatedTotal,
          totalWeight: updatedTotalWeight,
        };
      }

    case "REMOVE_ITEM":
      const removeFromCart = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );
      if (removeFromCart) {
        renderToastMsg(
          `Removed ${removeFromCart.quantity} ${
            action.payload.product?.productName ??
            action.payload.product?.product_name
          } from the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) -
            parseFloat(action.payload.product.price * removeFromCart.quantity)
          ).toFixed(2)
        );

        const updatedTotalWeight = isNumber(
          (
            parseFloat(state.totalWeight) -
            parseFloat(
              action.payload.product.unit_size * removeFromCart.quantity
            )
          ).toFixed(2)
        );

        return {
          ...state,
          items: state.items.filter(
            (item) => item.product.id !== action.payload.product.id
          ),
          total: updatedTotal,
          totalWeight: updatedTotalWeight,
        };
      }
      break;

    case "ADD_ITEMS_TO_CART":
      // Add an array of items to the cart
      const updatedItems = action.payload.map((item) => ({
        product: item,
        quantity: item.order_qty,
      }));

      const updatedTotal = isNumber(
        (
          parseFloat(state.total) +
          action.payload.reduce(
            (total, item) => total + parseFloat(item.price),
            0
          )
        ).toFixed(2)
      );

      const updatedTotalWeight = isNumber(
        (
          parseFloat(state.totalWeight) +
          action.payload.reduce(
            (totalWeight, item) => totalWeight + parseFloat(item.unit_size),
            0
          )
        ).toFixed(2)
      );

      return {
        ...state,
        items: [...state.items, ...updatedItems],
        total: updatedTotal,
        totalWeight: updatedTotalWeight,
      };
      break;

    case "CLEAR_CART":
      return {
        ...state,
        items: [],
        total: 0.0,
        totalWeight: 0.0,
      };

    default:
      return state;
  }
};

// Cart context provider component
export const EditCartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    setToStorage("editcart", cartState);
  }, [cartState]);

  return (
    <EditCartContext.Provider value={{ cartState, dispatch }}>
      {children}
    </EditCartContext.Provider>
  );
};

export const useEditCart = () => {
  const context = useContext(EditCartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
