import React, { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { CustomSelect, Notification } from "src/component";
import { isValidFileType, renderToastMsg } from "src/utils/functions";
import { validTypes } from "src/utils/constants";
import { getKyc, uploadKyc } from "src/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const KYC = () => {
  const { user } = useProtectedRoutesContext();

  const [frontPreview, setFrontPreview] = useState(null);

  const handleFrontFileChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFrontPreview(reader.result);
    };

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setFrontPreview(null);
    }
  };

  const { refetch, data } = useQuery({
    queryKey: ["kyc"],
    queryFn: getKyc,
    suspense: true,
  });

  const initialValues = {
    type: data?.data?.type ?? "",
    frontSide: data?.data?.front_side ?? null,
  };

  const validationSchema = yup.object().shape({
    type: yup.string().required("Please select document type"),
    frontSide: yup
      .mixed()
      .required("Document image is required")
      .test("is-valid-type", "Not a valid image type", (value) => {
        if (typeof value === "string" || value instanceof String) {
          return true;
        } else {
          return isValidFileType(
            value && value.name.toLowerCase(),
            "courseImg"
          );
        }
      })
      .test("is-valid-size", "Max allowed size is 1MB", (value) => {
        if (typeof value === "string" || value instanceof String) {
          return true;
        } else {
          return value && value.size <= 1024 * 1024;
        }
      }),
  });

  const { mutate, isLoading } = useMutation(uploadKyc, {
    onSuccess: (res) => {
      renderToastMsg(res?.message, "success");
      refetch();
    },
  });

  const validOptions = validTypes?.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const kycSubmitted =
    parseInt(user?.kyc_complete) === 1 &&
    parseInt(data?.data?.is_verified) === 0;

  return (
    <>
      <div className="py-[2rem] px-[4rem]">
        {kycSubmitted && <Notification message={"Awaiting approval"} />}

        <div className="my-14 lg:my-20 ">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ values, setFieldValue, handleChange, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center justify-center"
              >
                <div className="min-w-[70%]  flex-col justify-start items-center gap-[18px] inline-flex ">
                  <div className="text-center text-lightText  text-[3rem] font-bold  leading-[3rem]">
                    KYC Verification
                  </div>
                  <p className=" lg:w-[60%] text-center text-neutral-400 text-[1.6rem] font-normal leading-[3.6rem] ">
                    SendForce Logistics Requires a valid government issue ID
                    (Drivers License,International passport, National ID,
                    Voter's Card)
                  </p>

                  <div className="w-[60%] my-[10px]">
                    <p className="text-center text-lightText text-[1.6rem] font-medium ">
                      Choose the document type
                    </p>
                    <CustomSelect
                      title=""
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      options={validOptions}
                      disabled={kycSubmitted}
                    />
                  </div>
                  <div className=" min-w-[60%] flex flex-col lg:flex-row items-center justify-between gap-[40px] my-[20px] ">
                    <div className="bg-white bg-opacity-5 rounded-[10px] shadow py-10 px-12 w-full">
                      <p className="text-center text-lightText text-[1.6rem] font-semibold leading-[30px]">
                        Front side
                      </p>
                      <p className="text-center text-lightGray text-[1.4rem] font-normal leading-[25px]">
                        Supports: JPG, JPEG, PNG
                      </p>

                      <label className="flex flex-col items-center py-4 px-6 bg-white rounded-[5px] my-5  border border-[#E9E9E9] cursor-pointer ">
                        <span className=" text-[1.4rem] text-lightGray leading-normal font-medium">
                          Choose a File
                        </span>
                        <input
                          type="file"
                          className="hidden"
                          name="frontSide"
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => {
                            setFieldValue("frontSide", e.target.files[0]);
                            handleFrontFileChange(e);
                          }}
                          disabled={kycSubmitted}
                        />
                      </label>

                      {(typeof values.frontSide === "string" &&
                        values.frontSide) ||
                      frontPreview ? (
                        <div className="flex justify-center items-center my-3">
                          <img
                            src={
                              typeof values.frontSide === "string"
                                ? values.frontSide
                                : frontPreview
                            }
                            alt="Front Preview"
                            className="w-[12rem] rounded"
                          />
                        </div>
                      ) : null}

                      <ErrorMessage
                        name="front_side"
                        component={"div"}
                        className="text-red-500 text-[1.2rem] my-3"
                      />
                    </div>
                  </div>
                </div>

                {kycSubmitted ? (
                  ""
                ) : (
                  <div className=" w-[40%] flex justify-center items-center my-24 lg:my-12">
                    <button
                      type="submit"
                      className="w-full btn-loader p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium my-[10px]"
                      disabled={isLoading}
                    >
                      Verify Account
                    </button>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default KYC;
