import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { ErrorMessage } from "formik";

const GooglePlaceInput = ({ title, name, validate = true, ...rest }) => {
  const customStyles = {
    placeholder: (styles) => ({
      ...styles,
      color: "var(--light-gray)",
      fontSize: "1.4rem",
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#fff" : "#667085",
      fontSize: "1.4rem",
      backgroundColor: state.isSelected ? "#0059ad" : "#fffff",
      "&:hover": {
        backgroundColor: "var(--primary-color)",
        color: "#ffffff",
        cursor: "pointer",
      },
    }),
    control: (styles) => ({
      ...styles,
      fontSize: "1.4rem",
      color: "#667085",
      minHeight: "0",
      padding: "5px",
      border: "1px solid #b3b2b2",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      "&:focus": {
        borderColor: "var(--primary-color)",
      },
    }),
  };

  return (
    <div>
      <p className="text-lightGray mb-[1rem] text-[1.6rem]">{title}</p>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_PLACE_API}
        selectProps={{
          styles: customStyles,
          ...rest,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["ng"],
          },
        }}
      />
      {validate && (
        <ErrorMessage
          name={name}
          component="div"
          className="text-red-500 text-[1.2rem] my-3"
        />
      )}
    </div>
  );
};

export default GooglePlaceInput;
