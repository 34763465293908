import { lazy } from "react";

const Dashboard = lazy(() => import("./Dashboard"));
const Profile = lazy(() => import("./Profile"));
const Inventory = lazy(() => import("./Products"));
const Wallet = lazy(() => import("./Wallet"));
const Tracker = lazy(() => import("./Tracker"));
const Request = lazy(() => import("./Request/Request"));
const Product = lazy(() => import("./Products/Product"));
const ViewProduct = lazy(() => import("./Products/ViewProduct"));
const RequestDetails = lazy(() => import("./Request/RequestDetails"));
const CreateRequests = lazy(() => import("./Request/CreateRequests"));
const ConsignmentForm = lazy(() => import("./Request/ConsignmentForm"));
const DeliveryForm = lazy(() => import("./Request/DeliveryForm"));
const PickupDeliveryForm = lazy(() => import("./Request/PickupDeliveryForm"));
const EditRequest = lazy(() => import("./Request/EditRequest"));

export {
  Dashboard,
  Profile,
  Inventory,
  Wallet,
  Tracker,
  Request,
  Product,
  ViewProduct,
  RequestDetails,
  CreateRequests,
  ConsignmentForm,
  DeliveryForm,
  PickupDeliveryForm,
  EditRequest,
};
