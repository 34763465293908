import React from "react";

import noImage from "src/assets/images/no-image.png";

import { convertToCurrency } from "src/utils/functions";
import { QuantityInput } from "../component";

const AddProductCart = ({ product, setAddToCartModalOpen, requestType }) => {
  const variants = product?.variants;

  return (
    <>
      {variants?.map((variant, index) => (
        <div
          className="border-b border-b-lightGrey pb-[3rem] mb-[2rem]"
          key={index}
        >
          <div className="flex items-center flex-wrap gap-12">
            <img
              src={variant?.avatar ?? noImage}
              alt={variant?.name}
              className={`${
                variant?.avatar ? "w-[100px]" : "w-[60px]"
              } object-contain`}
            />
            <div>
              <p className="text-dark text-[1.8rem] font-semibold break-all mb-2">
                {variant?.variant_name}
              </p>
              <div className="flex gap-10 items-center mb-2">
                <p className="text-darkGray text-[1.4rem] font-medium">
                  {convertToCurrency(Number(variant?.price))}
                </p>
              </div>

              {requestType === "delivery" &&
              parseInt(variant?.virtual_quantity) === 0 ? (
                <p className="text-[1.4rem] text-red-400">out of stock</p>
              ) : (
                <QuantityInput
                  product={variant}
                  productName={product?.product_name}
                  quantity={variant?.virtual_quantity}
                />
              )}  
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AddProductCart;
