import React from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
// import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import {
  DashboardIcon,
  InventoryIcon,
  LogoutIcon,
  ProfileIcon,
  RequestIcon,
  WalletIcon,
  TrackerIcon,
} from "src/assets/svg";
import Logo from "src/assets/images/logo.png";

const SideBar = () => {
  // const { setUser, setToken } = useProtectedRoutesContext();

  const { pathname } = useLocation();
  return (
    <Sidebar
      style={{ borderColor: "#cfcfcf" }}
      backgroundColor="white"
      customBreakPoint="1000px"
      width="220px"
    >
      <div className="p-[20px]">
        <img src={Logo} alt="sendforce" className="w-[8rem]" />
      </div>
      <Menu
        menuItemStyles={{
          root: ({ active }) => {
            return {
              color: active ? "#FFFFFF" : "#525252",
              backgroundColor: active ? "#64748B" : undefined,
              marginBottom: 5,
            };
          },
          button: {
            paddingInline: 15,
          },
          icon: ({ active }) => ({
            marginRight: 5,
            color: active ? "#ffffff" : "var(--primary-color)",
            "&:hover": {
              color: "#ffffff",
            },
          }),
          label: {
            fontSize: "1.6rem",
            fontWeight: "500",
          },
        }}
        rootStyles={{
          // width: "80%",
          marginInline: "auto",
          marginTop: 20,
        }}
      >
        <MenuItem
          component={<Link to="/" />}
          icon={<DashboardIcon />}
          active={pathname === "/dashboard"}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          component={<Link to="/inventory" />}
          icon={<InventoryIcon />}
          active={pathname.endsWith("inventory")}
        >
          Inventory
        </MenuItem>
        <MenuItem
          component={<Link to="/requests" />}
          icon={<RequestIcon />}
          active={pathname.endsWith("requests")}
        >
          Requests
        </MenuItem>
        <MenuItem
          component={<Link to="/tracker" />}
          icon={<TrackerIcon />}
          active={pathname.endsWith("tracker")}
        >
          Track
        </MenuItem>
        <MenuItem
          component={<Link to="/wallet" />}
          icon={<WalletIcon />}
          active={pathname.endsWith("wallet")}
        >
          Wallet
        </MenuItem>
        <MenuItem
          component={<Link to="/profile/1" />}
          icon={<ProfileIcon />}
          active={pathname.includes("profile")}
        >
          Profile
        </MenuItem>
        {/* <MenuItem
          component={<Link to="#" />}
          icon={<SettingsIcon />}
          active={pathname.endsWith("settings")}
        >
          Settings
        </MenuItem> */}

        <MenuItem
          className="mt-auto"
          icon={<LogoutIcon />}
          onClick={() => {
            // setToken(null);
            // setUser(null);
            localStorage.clear();
            window.location.reload();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
