import React, { useState } from "react";
import logo from "src/assets/images/logo.png";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CustomInput, CustomSelect } from "src/component";
import { LiaArrowLeftSolid } from "react-icons/lia";
import { BannerLayout } from "./layout/AuthLayout";
import { businessType } from "src/utils/constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCategories, register } from "src/api";
import { renderAlert, setToStorage } from "src/utils/functions";

const CreateAccount = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });

  const validationSchemaFirst = yup.object().shape({
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    phone: yup
      .string()
      .required("Phone number is required")
      .matches(
        /^234\d{10}$/,
        "Invalid phone number. Please enter a valid phone number starting with 234."
      ),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email Address is required"),
    business_name: yup.string().required("Business Name is required"),
    business_type: yup.string().required("Business Type is required"),
  });

  const validationSchemaSecond = yup.object().shape({
    industry: yup.string().required("Industry is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    password1: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    isChecked: yup
      .boolean()
      .oneOf([true], "You must accept Terms and Conditions and Privacy Policy"),
  });

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    business_name: "",
    business_type: "",
    industry: "",
    referral_code: searchParams.get("refCode") ?? "",
    phone: "",
    password: "",
    password1: "",
    isChecked: false,
  };

  const { mutate, isLoading } = useMutation(register, {
    onSuccess: (data) => {
      setToStorage("email", data?.user?.email);
      renderAlert({
        title: "Success",
        msg: data?.message,
        type: data?.status,
      }).then((result) => {
        // result.isConfirmed ? navigate("/") : navigate("/");
        result.isConfirmed ? navigate("/otp?c=true") : navigate("/otp?c=true");
      });
    },
    onError: (error) => {
      // renderToastMsg(error.message);
    },
  });

  const handleSubmit = (values) => {
    if (step === 1) {
      validationSchemaFirst
        .validate(values)
        .then(() => {
          setStep(2); // Change to step 2 on successful validation
        })
        .catch((errors) => {
          // console.log(errors);
        });
    } else {
      validationSchemaSecond
        .validate(values)
        .then(({ isChecked, password1, ...result }) => {
          mutate(result);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 h-[100vh]">
          <BannerLayout />
          <div className="col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="w-[500px] mx-auto my-[50px] px-[50px] min-h-[80%] relative">
                <img src={logo} alt="sendforce logo" />
                <div className="my-[100px]">
                  <p className=" text-dark text-[2.4rem] font-bold leading-none mb-8">
                    Create an account
                  </p>
                  <p className="w-[323px] text-lightGray text-[1.6rem] font-normal ">
                    Complete the sign up process to get started
                  </p>

                  <div className="my-10 w-[50%]">
                    <div className="flex items-center">
                      <div className="flex items-center relative">
                        <div className="transition duration-500 ease-in-out h-[1.5rem] w-[2rem] py-3 border-2 border-primary bg-primary"></div>
                      </div>
                      <div
                        className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                          step === 2 ? "border-primary" : "border-grey"
                        }`}
                      ></div>
                      <div className="flex items-center relative">
                        <div
                          className={`transition duration-500 ease-in-out h-[1.5rem] w-[2rem] py-3 border-2 ${
                            step === 2
                              ? " border-primary bg-primary"
                              : " bg-gray-400 border-gray-400"
                          }`}
                        ></div>
                      </div>
                    </div>
                  </div>

                  {/* form */}
                  <div className="my-[30px]">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={
                        step === 1
                          ? validationSchemaFirst
                          : validationSchemaSecond
                      }
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          {step === 1 && (
                            <>
                              <CustomInput
                                title="First Name"
                                name="firstname"
                                onChange={handleChange}
                                value={values.firstname}
                                onBlur={handleBlur}
                              />
                              <CustomInput
                                title="Last Name"
                                name="lastname"
                                onChange={handleChange}
                                value={values.lastname}
                                onBlur={handleBlur}
                              />
                              <CustomInput
                                title="Phone Number"
                                subTitle="(Please start phone number with 234)"
                                name="phone"
                                onChange={handleChange}
                                value={values.phone}
                                onBlur={handleBlur}
                              />
                              <CustomInput
                                title="Email Address"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                onBlur={handleBlur}
                              />
                              <CustomInput
                                title="Business Name"
                                name="business_name"
                                onChange={handleChange}
                                value={values.business_name}
                                onBlur={handleBlur}
                              />
                              <CustomSelect
                                title="Business Type"
                                name="business_type"
                                value={values.business_type}
                                onChange={handleChange}
                                options={businessType}
                              />
                            </>
                          )}

                          {step === 2 && (
                            <>
                              <div
                                className=" my-[30px] flex items-center gap-2 cursor-pointer"
                                onClick={() => {
                                  if (!isLoading) {
                                    setStep(1);
                                  }
                                }}
                              >
                                <LiaArrowLeftSolid
                                  size={20}
                                  className="text-primary"
                                />
                                <p className="text-primary text-[1.4rem] font-medium  leading-none">
                                  Back
                                </p>
                              </div>
                              <CustomSelect
                                title="Industry"
                                name="industry"
                                value={values.industry}
                                onChange={handleChange}
                                options={categories?.data?.map((datum) => ({
                                  value: datum?.id,
                                  label: datum?.category_name,
                                }))}
                              />
                              <CustomInput
                                title="Referral code"
                                name="referral_code"
                                onChange={handleChange}
                                value={values.referral_code}
                              />
                              <CustomInput
                                title="Password"
                                name="password"
                                passwordToggle
                                onChange={handleChange}
                                value={values.password}
                                onBlur={handleBlur}
                              />
                              <CustomInput
                                title="Confirm Password"
                                name="password1"
                                passwordToggle
                                onChange={handleChange}
                                value={values.password1}
                                onBlur={handleBlur}
                              />

                              <div className="flex items-center justify-start gap-5">
                                <input
                                  type="checkbox"
                                  name="isChecked"
                                  onChange={handleChange}
                                  className=" accent-primary focus:accent-primary hover:accent-primary"
                                  checked={values.isChecked}
                                />

                                <div>
                                  <span className="text-lightGray text-[1.4rem] font-normal leading-none">
                                    By ticking this box, you agree to our
                                  </span>
                                  <a
                                    href="https://sendforceng.com/terms"
                                    target="_blank"
                                    className="text-red-400 text-[1.4rem] font-normal ml-2 leading-none"
                                    rel="noreferrer"
                                  >
                                    Terms and conditions and
                                  </a>
                                  <a
                                    href="https://sendforceng.com/privacy"
                                    target="_blank"
                                    className="text-red-400 text-[1.4rem] font-normal ml-2 leading-none"
                                    rel="noreferrer"
                                  >
                                    private policy
                                  </a>
                                </div>
                              </div>
                              <ErrorMessage
                                name="isChecked"
                                component="div"
                                className="text-red-500 text-[1.2rem] mt-2"
                              />
                            </>
                          )}
                          <button
                            type="submit"
                            // onClick={handleSubmit}
                            disabled={isLoading}
                            className="btn-loader w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[30px]"
                          >
                            {step === 1 ? "Continue" : "Create Account"}
                          </button>

                          <div className="text-center my-[20px] ">
                            <p className="text-lightGray text-[1.3rem] font-normal mb-[10px] leading-none">
                              Already have an account?
                              <Link
                                to="/"
                                className=" text-primary text-[1.5rem] font-medium leading-none ml-2"
                              >
                                Sign In
                              </Link>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>

                  <div className="my-auto absolute text-center bottom-0 left-0 right-0">
                    <p className="text-lightGray  text-[1.4rem] font-medium leading-none">
                      sendforce logistics limited. All rights reserved.
                      <span className="ml-2">{new Date().getFullYear()}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
