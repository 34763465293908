/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./auth.module.css";
import logo from "src/assets/images/logo.png";
import OtpInput from "react-otp-input";
import { BannerLayout } from "./layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import { resendOTP, verifyOtp } from "src/api";
import {
  getFromStorage,
  renderToastMsg,
  setToStorage,
} from "src/utils/functions";
import { useNavigate, useSearchParams } from "react-router-dom";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [isdisabled, setIsdisabled] = useState(true);
  const [hasParam, setHasParam] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (otp.length === 6) {
      setIsdisabled(false);
    } else {
      setIsdisabled(true);
    }

    if (searchParams.has("c")) {
      setHasParam("c");
    } else if (searchParams.has("reset")) {
      setHasParam("reset");
    } else {
      navigate("/");
    }
  }, [otp]);

  const { mutate: resendOtpMutate, isLoading: otpIsLoading } = useMutation(
    resendOTP,
    {
      onSuccess: (res) => {
        renderToastMsg(res?.message, res.status);
      },
    }
  );
  const { mutate, isLoading } = useMutation(verifyOtp, {
    onSuccess: (res) => {
      renderToastMsg(res?.message, res.status);

      if (hasParam === "reset") {
        setToStorage("otp", res?.data?.otp);
        navigate("/reset-password");
      } else {
        localStorage.clear();
        navigate("/");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      id: hasParam,
      data: { otp: otp, email: getFromStorage("email") },
    });
  };

  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 h-[100vh]">
          <BannerLayout />
          <div className="col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2 ">
            <div className="flex flex-col items-center justify-center h-full  ">
              <div className="w-[500px] mx-auto my-[50px] px-[50px] relative min-h-[80%]  ">
                <img src={logo} alt="sendforce logo" />
                <div className="my-[100px]">
                  <p className=" text-dark text-[2.4rem] font-bold leading-none mb-8">
                    OTP Verification
                  </p>
                  <p className="w-[323px] text-lightGray text-[1.6rem] font-normal ">
                    A Six (6) digit code will be sent to your email address
                  </p>

                  {/* form */}
                  <div className="my-[30px]">
                    <form onSubmit={handleSubmit}>
                      <p className={styles.otpLabel}>Enter the code below</p>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        containerStyle={styles.otpContainer}
                        inputStyle={styles.otpInput}
                        renderInput={(props) => <input {...props} />}
                      />
                      <button
                        disabled={isdisabled || isLoading || otpIsLoading}
                        type="submit"
                        className={`w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px] 
                        ${isLoading || otpIsLoading ? " btn-loader" : ""}`}
                      >
                        Verify
                      </button>
                    </form>
                    <div className="text-center my-[20px] ">
                      <button
                        type="button"
                        className="cursor-pointer text-primary text-[1.5rem] font-medium leading-none ml-2"
                        onClick={() =>
                          resendOtpMutate({ email: getFromStorage("email") })
                        }
                        disabled={otpIsLoading}
                      >
                        Resend OTP
                      </button>
                    </div>
                  </div>
                </div>
                <div className="my-auto absolute left-0 right-0 text-center bottom-0">
                  <p className="text-lightGray  text-[1.4rem] font-medium leading-none">
                    sendforce logistics limited. All rights reserved.
                    <span className="ml-2">{new Date().getFullYear()}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
