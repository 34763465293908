import React from "react";
import { Suspense } from "react";
import ReactModal from "react-modal";
import Loader from "./Loader";
import { renderConfirmDialogue } from "src/utils/functions";

const Modal = ({ size, children, ...rest }) => {
  let modalClass;
  if (size === "xl") {
    modalClass = "modal-xlg";
  } else if (size === "large") {
    modalClass = "modal-lg";
  } else if (size === "md") {
    modalClass = "modal-md";
  } else if (size === "sm") {
    modalClass = "modal-sm";
  } else if (size === "xsm") {
    modalClass = "modal-xsm";
  } else {
    modalClass = "modal-normal";
  }
  return (
    <Suspense
      fallback={
        <ModalBody modalClass={modalClass} {...rest}>
          <Loader spin />
        </ModalBody>
      }
    >
      <ModalBody modalClass={modalClass} {...rest}>
        {children}
      </ModalBody>
    </Suspense>
  );
};

const ModalBody = ({
  children,
  isOpen,
  setIsOpen,
  title,
  subtitle,
  modalClass,
  showCloseButton = true,
  showFooter = false,
  onClose = () => {},
}) => {
  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className={`modal-dialog ${modalClass}`} role="document">
        <div className="modal-content app-modal-content">
          <div className="modal-header">
            <h4 className="capitalize modal-title">{title}</h4>

            {showCloseButton && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsOpen(false);
                  onClose();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <h6 className="modal-subtitle">{subtitle}</h6>
          <div className="modal-body rec-modal-body">{children}</div>
          {showFooter && (
            <div className="flex justify-end items-center px-[30px] py-[15px]">
              <button
                type="button"
                className=" close px-8 py-4  bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium  my-[10px]"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  renderConfirmDialogue(
                    "Please confirm, you have added the items to your cart?",
                    "",
                    "Continue"
                  ).then((confirmed) => {
                    if (confirmed) {
                      setIsOpen(false);
                      onClose();
                    }
                  });
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
