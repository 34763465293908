import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { CustomInput, SearchableSelect } from "../../component";
import * as yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addBankDetails, getBanks, verifyAccountNumber } from "src/api";
import { renderAlert } from "src/utils/functions";

const AddBankDetails = ({ setIsBankModalOpen, refetch }) => {
  const [selectBank, setSelectBank] = useState();
  const [accNumber, setAccNumber] = useState();
  const [run, setRun] = useState(false);

  const initialValues = {
    account_number: "",
    banks: "",
  };

  const validationSchema = yup.object().shape({
    account_number: yup
      .string()
      .required("Account Number is required")
      .matches(/^[0-9]{10}$/, "Account Number must be exactly 10 digits"),
    banks: yup.string().required("Bank is required"),
  });

  const { data: banks } = useQuery({
    queryKey: ["banks"],
    queryFn: getBanks,
    suspense: true,
  });

  const { data: accountData, isLoading } = useQuery({
    queryKey: ["verifyAccount", accNumber, selectBank],
    queryFn: () =>
      verifyAccountNumber({ account: accNumber, bankCode: selectBank?.value }),
    enabled: run,
    onSuccess: (res) => {
      setRun(false);
    },
  });

  const { mutate, isLoading: addBankIsLoading } = useMutation(addBankDetails, {
    onSuccess: (res) => {
      refetch();
      renderAlert({
        msg: res?.message,
        title: "Success",
        type: res.status,
      }).then(() => {
        setIsBankModalOpen(false);
      });
    },
  });

  const accountDataStatus = accountData?.data?.status === false;
  const accountDetails = accountData?.data?.data;

  useEffect(() => {
    if (accNumber?.length === 10 && selectBank) {
      setRun(true);
    }
  }, [accNumber, selectBank]);

  return (
    <>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const data = {
              bank_code: selectBank?.value,
              bank_name: selectBank?.label,
              account_name: accountDetails?.account_name,
              account_number: accountDetails?.account_number,
            };

            mutate(data);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="flex flex-col">
              <div className="mb-[0px] ">
                <CustomInput
                  title="Account Number"
                  name="account_number"
                  onChange={(e) => {
                    handleChange(e);
                    setAccNumber(e.target.value);
                  }}
                  value={values.account_number}
                  onBlur={handleBlur}
                />
              </div>

              <SearchableSelect
                title="Bank"
                options={banks?.data?.data?.map((bank) => ({
                  value: bank?.code,
                  label: bank?.name,
                }))}
                name="banks"
                onChange={(opt) => {
                  if (opt?.value) {
                    setSelectBank(opt);
                  } else {
                    setSelectBank("");
                  }
                  setFieldValue("banks", opt?.value);
                }}
                onBlur={handleBlur}
              />
              {accountDataStatus ? (
                <div className="mb-10 mt-3 text-red-400  text-[1.4rem]">
                  Invalid Account Number
                </div>
              ) : (
                <div className="mb-10 mt-3 text-lightText text-[1.5rem]">
                  Account Name: {accountDetails?.account_name}
                </div>
              )}

              {!accountDataStatus && accNumber && selectBank && (
                <div className="flex justify-center my-4 ">
                  <button
                    disabled={isLoading || addBankIsLoading}
                    type="submit"
                    className="btn-loader  w-full p-[15px] bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[10px]"
                  >
                    Add Bank Information
                  </button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddBankDetails;
