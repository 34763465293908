import React, { useState } from "react";
import { useMutation, useQueries } from "@tanstack/react-query";
import {
  getNotification,
  getUnreadNotification,
  getUpdateNotification,
} from "src/api";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { formatCustomDate } from "src/utils/functions";
import { Modal } from "src/component";
import { ViewNotification } from "src/modals";

const Notifications = () => {
  const { user } = useProtectedRoutesContext();
  const [isActive, setIsActive] = useState("all");
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationData, setNotificationData] = useState("");

  const [{ data, refetch }, { data: unRead, refetch: unReadRefetch }] =
    useQueries({
      queries: [
        {
          queryKey: ["allNotification"],
          queryFn: () => getNotification(user?.id),
          suspense: true,
        },
        {
          queryKey: ["unreadNotification"],
          queryFn: () => getUnreadNotification(user?.id),
          suspense: true,
        },
      ],
    });

  const { mutate } = useMutation(getUpdateNotification, {
    onSuccess: () => {
      refetch();
      unReadRefetch();
    },
  });

  // Sort the notifications by created_at in descending order
  const sortedData = data?.data?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const sortedUnreadData = unRead?.data?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <>
      <div className=" border-b border-gray-200">
        <p className="py-[2rem] px-[2rem] md:px-[5rem] text-lightText text-[2rem] font-semibold">
          Notifications
        </p>
      </div>

      <div className=" px-[2rem] md:px-[5rem]">
        <div className="my-[4rem]">
          <div className="flex flex-wrap gap-[2rem]">
            <p
              className={`text-[1.6rem] py-[1rem] px-[2rem] border border-primary rounded-[1rem]  cursor-pointer hover:bg-primary hover:text-white ${
                isActive === "all" ? "bg-primary text-white" : "text-lightText"
              }`}
              onClick={() => {
                setIsActive("all");
              }}
            >
              All
            </p>
            <p
              className={`text-[1.6rem] py-[1rem] px-[2rem] border border-primary rounded-[1rem]  cursor-pointer hover:bg-primary hover:text-white ${
                isActive === "unread"
                  ? "bg-primary text-white"
                  : "text-lightText"
              }`}
              onClick={() => {
                setIsActive("unread");
              }}
            >
              Unread
            </p>
          </div>

          {isActive === "all" && (
            <div className="my-[3rem]">
              {sortedData?.length > 0 ? (
                sortedData?.map((datum, i) => (
                  <div
                    key={i}
                    className="border py-[1rem] px-[2rem] rounded-[1rem] cursor-pointer my-[2rem]"
                    onClick={() => {
                      setIsNotificationModalOpen(true);
                      setNotificationData(datum);
                      if (datum?.status === "unread") {
                        mutate({ notification_ids: [datum?.id] });
                      }
                    }}
                  >
                    {datum?.status === "unread" && (
                      <p className=" text-[1.4rem] text-primary font-medium ">
                        Unread
                      </p>
                    )}
                    <div className="flex justify-between items-center flex-wrap">
                      <p className=" py-2 text-[1.6rem] leading-[2.6rem] text-lightText">
                        {datum?.header}
                      </p>

                      <p className="text-primary text-[1.4rem]">
                        {formatCustomDate(datum?.created_at)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-[1.8rem] font-medium text-center text-darkGray">
                  No Notifications
                </p>
              )}
            </div>
          )}

          {isActive === "unread" && (
            <div className="my-[3rem]">
              {sortedUnreadData?.length > 0 ? (
                sortedUnreadData?.map((datum, i) => (
                  <div
                    key={i}
                    className="border py-[1rem] px-[2rem] rounded-[1rem] cursor-pointer my-[2rem]"
                    onClick={() => {
                      setIsNotificationModalOpen(true);
                      setNotificationData(datum);
                      if (datum?.status === "unread") {
                        mutate({ notification_ids: [datum?.id] });
                      }
                    }}
                  >
                    {datum?.status === "unread" && (
                      <p className=" text-[1.4rem] text-primary font-medium ">
                        Unread
                      </p>
                    )}
                    <div className="flex justify-between items-center flex-wrap">
                      <p className=" py-2 text-[1.6rem] leading-[2.6rem] text-lightText">
                        {datum?.header}
                      </p>

                      <p className="text-primary text-[1.4rem]">
                        {formatCustomDate(datum?.created_at)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-[1.8rem] font-medium text-center text-darkGray">
                  No Unread Notifications
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isNotificationModalOpen}
        setIsOpen={setIsNotificationModalOpen}
        title="Notification"
        size="md"
      >
        <ViewNotification data={notificationData} />
      </Modal>
    </>
  );
};

export default Notifications;
