import React from "react";
import { PiWarningCircle } from "react-icons/pi";

const Notification = ({ message }) => {
  return (
    <>
      <div className="flex flex-col gap-[10px] justify-end items-end md:flex-row mb-[3rem]">
        <div className="p-[5px] md:px-[17px] md:py-[10px] bg-amber-50 rounded-[5px] shadow border border-amber-500 flex-col justify-end items-start gap-2.5 inline-flex">
          <div className="justify-start items-center gap-1.5 inline-flex">
            <PiWarningCircle size="2.5rem" className="text-amber-500" />
            <div className="text-center text-dark text-[1.2rem] md:text-[1.4rem] font-medium leading-none">
              {message}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
