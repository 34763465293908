import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { deleteProductVariant } from "src/api";
import Modal from "src/component/Modal";
import {
  convertToCurrency,
  renderAlert,
  renderToastMsg,
} from "src/utils/functions";
import { EditVariant } from "..";

import noImage from "src/assets/images/no-image.png";

const ViewVariant = ({
  variants,
  productId,
  refetch,
  setViewVariantModalOpen,
}) => {
  const [editVariantModalOpen, setEditVariantModalOpen] = useState(false);
  const [SelectedVariant, setSelectedVariant] = useState(null);

  const { mutate, isLoading } = useMutation(deleteProductVariant, {
    onSuccess: (data) => {
      renderAlert({
        title: "Success",
        msg: data?.message,
        type: data?.status,
      });
      refetch();
      setViewVariantModalOpen(false);
    },
    onError: (error) => {
      renderToastMsg(error?.error);
    },
  });

  const handleEditVariant = (variant) => {
    // setViewVariantModalOpen(false);
    setSelectedVariant(variant);
    setEditVariantModalOpen(true);
  };

  return (
    <>
      {variants?.map((variant, index) => (
        <div
          className="border-b border-b-lightGrey pb-[3rem] mb-[2rem]"
          key={index}
        >
          <div className="flex items-center flex-wrap gap-12">
            <img
              src={variant?.avatar ?? noImage}
              alt={variant?.name}
              className={`${
                variant?.avatar ? "w-[100px]" : "w-[60px]"
              } object-contain`}
            />
            <div>
              <p className="text-darkGray text-[1.4rem]">{variant?.upc_code}</p>
              <p className="text-dark text-[2rem] font-semibold">
                {variant?.variant_name}
              </p>
              <div className="flex gap-10 items-center">
                <p className="text-darkGray text-[1.4rem] font-medium">
                  Price: {convertToCurrency(Number(variant?.price))}
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-10 items-center mt-4">
            <button
              className="px-8 py-4 w-full bg-primary rounded-[5px] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium"
              onClick={() => handleEditVariant(variant)}
            >
              Edit Variant
            </button>
            <button
              type="button"
              disabled={isLoading}
              onClick={() => mutate(variant?.id)}
              className=" px-8 py-4 w-full border-danger border rounded-[5px] justify-center items-center gap-2.5 iflex text-center text-danger text-[1.4rem] font-medium"
            >
              Delete Variant
            </button>
          </div>
        </div>
      ))}
      {variants.length === 0 && (
        <p className="text-darkGray text-[1.4rem] text-center font-medium">
          No Variant record
        </p>
      )}

      <Modal
        isOpen={editVariantModalOpen}
        setIsOpen={setEditVariantModalOpen}
        title="Edit Product Variant"
        size="large"
      >
        <EditVariant
          setIsVariantModalOpen={setEditVariantModalOpen}
          variant={SelectedVariant}
        />
      </Modal>
    </>
  );
};

export default ViewVariant;
