import React from "react";
import { renderNaira } from "src/utils/functions";
import noImage from "src/assets/images/no-image.png";

const ProductCard = ({ title, products }) => {
  return (
    <>
      <div className="relative bg-white rounded-lg shadow border">
        <div className="border-b-2 border-b-[#a7a7a74f]">
          <p className="text-primary text-[1.6rem] font-semibold leading-none p-[2.5rem]">
            {title}
          </p>
        </div>

        <div className="max-h-[80vh]  overflow-y-auto">
          {products?.map((product, index) => (
            <div className="border-b border-gray-200" key={index}>
              <div className="py-[2rem] px-[2rem]">
                <div className="flex items-center gap-10">
                  <div className="w-[100px] flex items-center justify-center">
                    <img
                      src={product?.avatar ?? noImage}
                      alt={product?.name}
                      className={`${
                        product?.avatar ? "w-[100px]" : "w-[60px]"
                      } object-contain`}
                    />
                  </div>
                  <div className="lg:w-[70%]">
                    <p className="text-dark font-semibold">
                      {product?.product_name}
                    </p>
                    <div className="flex gap-10 text-[1.4rem] text-lightGray my-2 font-normal">
                      <p>Variant: {product?.variant_name}</p>
                    </div>
                    <div className="flex justify-between items-center text-[1.5rem] font-medium text-dark gap-5">
                      <p className="font-medium">
                        {renderNaira(product?.price)}
                      </p>
                      <p>Quantity: {product?.order_qty}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
