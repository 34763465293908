import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { PiPackage } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const RequestModal = ({ setIsRequestModalOpen }) => {
  const [seletedRequest, setSeletedRequest] = useState();
  return (
    <>
      <div className="flex justify-start gap-2 items-center">
        <p className="text-lightGray text-[1.4rem] my-5">Select Request Type</p>

        <AiOutlineInfoCircle
          size="2rem"
          data-tooltip-id="my-tooltip"
          className="cursor-pointer text-primary"
        />

        <Tooltip id="my-tooltip" events={["click"]}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="mb-3 text-[1.3rem]">
              Consignment Request: Request from Merchant to Fulfilment Centre
            </span>
            <span className="mb-3 text-[1.3rem]">
              Delivery Request: Request from Fulfilment Centre to Customer
            </span>
            <span className="mb-3 text-[1.3rem]">
              Pickup and Delivery Request: Request from Merchant to Customer
            </span>
          </div>
        </Tooltip>
      </div>
      <div className=" flex flex-col items-start md:flex-row justify-between md:items-center gap-5 ">
        <div
          className={`p-8 cursor-pointer ${
            seletedRequest === "delivery"
              ? "bg-primary text-white"
              : " text-primary"
          } rounded-[5px] border-[1.3px] border-primary  h-[12rem] w-[25rem] justify-end flex-col items-start gap-2.5 flex text-center  text-[1.4rem] font-medium  my-[10px]  hover:text-white  hover:scale-[.98] hover:bg-primary transition-border transform duration-300 ease-in-out hover:transform`}
          onClick={() => setSeletedRequest("delivery")}
        >
          <MdOutlineDeliveryDining size="3rem" />

          <p>Delivery Request</p>
        </div>
        <div
          className={`p-8 cursor-pointer ${
            seletedRequest === "consignment"
              ? "bg-primary text-white"
              : " text-primary"
          } rounded-[5px] border-[1.3px] border-primary  h-[12rem] w-[25rem] justify-end flex-col items-start gap-2.5 flex text-center  text-[1.4rem] font-medium  my-[10px]  hover:text-white  hover:scale-[.98] hover:bg-primary transition-border transform duration-300 ease-in-out hover:transform`}
          onClick={() => setSeletedRequest("consignment")}
        >
          <PiPackage size="3rem" />

          <p>Consignment Request</p>
        </div>
        <div
          className={`p-8 cursor-pointer ${
            seletedRequest === "pickupAndDelivery"
              ? "bg-primary text-white"
              : " text-primary"
          } rounded-[5px] border-[1.3px] border-primary  h-[12rem] w-[25rem] justify-end flex-col items-start gap-2.5 flex text-center  text-[1.4rem] font-medium  my-[10px]  hover:text-white  hover:scale-[.98] hover:bg-primary transition-border transform duration-300 ease-in-out hover:transform`}
          onClick={() => setSeletedRequest("pickupAndDelivery")}
        >
          <TbTruckDelivery size="3rem" />

          <p>Pickup & Delivery Request</p>
        </div>
      </div>

      <div className="flex md:justify-center my-8 ">
        {seletedRequest && (
          <>
            <Link
              to={`/requests/create-request/${seletedRequest}`}
              type="submit"
              className=" w-full p-[15px] border border-primary rounded-[5px] justify-center items-center gap-2.5 flex text-center text-primary text-[1.4rem] font-medium leading-none my-[10px] md:w-[50%] "
            >
              Create Request
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default RequestModal;
