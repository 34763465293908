import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React from "react";
import { trackRequest } from "src/api";

const TrackItems = ({ requestData }) => {
  const { data } = useQuery({
    queryKey: ["track-item-history", requestData?.uuid],
    queryFn: () => trackRequest(requestData?.uuid),
    suspense: true,
  });

  return (
    <>
      <ul className="progress">
        {data?.data?.order_tracking?.map((datum, index) => (
          <li
            className={`progress__item  progress__item--completed`}
            key={index}
          >
            <p className="capitalize progress__title">{datum?.status}</p>
            <p className="capitalize progress__info">
              {format(parseISO(datum?.created_at), "dd MMM yyyy, HH:mm")}
            </p>
          </li>
        ))}
      </ul>

      {data?.data?.length === 0 && (
        <div className="text-center text-lightText">Process yet to begin</div>
      )}
    </>
  );
};

export default TrackItems;
