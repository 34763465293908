import { format, parseISO } from "date-fns";
import React from "react";
import {
  renderNaira,
  renderStatusColor,
  renderStatusText,
} from "src/utils/functions";

const TransactionDetails = ({ details }) => {
  return (
    <>
      <div className="flex justify-between items-center flex-wrap gap-[2rem] ">
        <p className="text-lightText text-[2.5rem]">
          {renderNaira(details?.amount)}
        </p>
        <p
          className={`capitalize font-medium text-[1.6rem] ${renderStatusColor(
            details?.status
          )}`}
        >
          {details?.status}
        </p>
      </div>
      <div className="flex justify-between items-center flex-wrap gap-[2rem]  my-[2rem]">
        <p className="text-lightText text-[1.4rem]">Amount</p>
        <p className="text-lightText text-[1.4rem] capitalize">
          {renderNaira(details?.amount)}
        </p>
      </div>
      <div className="flex justify-between items-center flex-wrap gap-[2rem]  my-[2rem]">
        <p className="text-lightText text-[1.4rem]">Transaction Type</p>
        <p className="text-lightText text-[1.4rem] capitalize">
          {renderStatusText(details?.type)}
        </p>
      </div>

      <div className="flex justify-between items-center flex-wrap gap-[2rem]  my-[2rem]">
        <p className="text-lightText text-[1.4rem]">Transaction Ref</p>
        <p className="text-lightText text-[1.4rem] capitalize">
          {details?.transRef}
        </p>
      </div>

      <div className="flex justify-between items-center flex-wrap gap-[2rem]  my-[2rem]">
        <p className="text-lightText text-[1.4rem]">Narration</p>
        <p className="text-lightText text-[1.4rem] capitalize">
          {details?.description}
        </p>
      </div>

      <div className="flex justify-between items-center flex-wrap gap-[2rem]  my-[2rem]">
        <p className="text-lightText text-[1.4rem]">Transaction Date</p>
        <p className="text-lightText text-[1.4rem] capitalize">
          {format(parseISO(details?.created_at), "dd MMM yyyy HH:mm a")}
        </p>
      </div>
    </>
  );
};

export default TransactionDetails;
